import React, { useEffect, useState } from "react";
import "../../styling/appraisal/OverallPerformanceReview.css";
import { Box, Typography, Grid, Checkbox } from "@mui/material";
import Edit_icon from "../../images/mdi_edit-outline.png";
import axiosInstance from "../../utils/axiosInstance";
import { useSelector, useDispatch } from "react-redux";

const OverallPerformanceReview = ({
  validate,
  employeeId,
  apprslId,
  onEditContent,
}) => {
  const userRole = sessionStorage.getItem("role");
  const [data, setData] = useState(null);
  const isDataUploaded = useSelector((state) => state.appraisalSlice.isDataUploaded)

  // convert html to text
  const converHTMLToPlain = (html) => {
    var tempDivElement = document.createElement("div");
    tempDivElement.innerHTML = html;
    return tempDivElement.textContent || tempDivElement.innerText || "";
  };

  useEffect(() => {
    axiosInstance // get the particular appraisal data
      .get(`/appraisals/${apprslId}`)
      .then((res) => {
        setData(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [apprslId, isDataUploaded]); // Ensure apprslId is a dependency

  const EditContents = (page) => {
    onEditContent(apprslId, page);
  };
  
  if (!data) {
    return <p>Loading...</p>; // Show a loading message while data is being fetched
  }

  return (
    <Box className="main_container">
      <Box>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            marginTop: "0.75vw",
            fontSize: "1vw",
          }}
        >
          <Typography
            gutterBottom
            sx={{ fontSize: "1.5vw", paddingTop: "10px" }}
          >
            General Information
          </Typography>
          <Typography gutterBottom sx={{ fontSize: "1.25vw", padding: "10px" }}>
            {userRole !== "user" && <img
              src={Edit_icon}
              width={"25px"}
              height={"25px"}
              onClick={() => EditContents("generalInfo")}
              className="cursor"
            />}
          </Typography>
        </Box>

        <Grid
          container
          spacing={2}
          sx={{
            width: "74vw",
            paddingRight: "10px",
            backgroundColor: "#BABABA",
            marginLeft: "0px",
            marginTop: "0px",
          }}
        >
          <Grid item xs={12} sm={4}>
            Employee Name : {data.employee_name}
          </Grid>
          <Grid item xs={12} sm={4}>
            Manager Name : {data.manager_name}
          </Grid>
          <Grid item xs={12} sm={4}>
            Review Date : {data.review_date}
          </Grid>
          <Grid item xs={12} sm={4}>
            Department : {data.department}
          </Grid>
          <Grid item xs={12} sm={4}>
            Role : {data.designation}
          </Grid>
          <Grid item xs={12} sm={4}>
            Type of Review : {data.type_of_review}
          </Grid>
        </Grid>
      </Box>
      <Box>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            marginTop: "0.75vw",
            fontSize: "1vw",
          }}
        >
          <Typography
            gutterBottom
            sx={{ fontSize: "1.5vw", paddingTop: "10px" }}
          >
            Responsibilities
          </Typography>
          <Typography gutterBottom sx={{ fontSize: "1.25vw", padding: "10px" }}>
            {userRole !== "user" && <img
              src={Edit_icon}
              width={"25px"}
              height={"25px"}
              onClick={() => EditContents("Responsibilities")}
              className="cursor"
            />}
          </Typography>
        </Box>
        <Box sx={{ border: "1px solid black" }}>
          <Typography
            gutterBottom
            sx={{ fontSize: "1.25vw", paddingTop: "25px", paddingLeft: "25px" }}
          >
            Key Responsibilities
          </Typography>
          <ul>
            {converHTMLToPlain(data.key_responsibilities)
              .split(".")
              .map((item) => item.trim())
              .filter((item) => item !== "")
              .map((item, index) => (
                <li key={index}>{item}</li>
              ))}
          </ul>
          <Typography
            gutterBottom
            sx={{ fontSize: "1.25vw", paddingTop: "10px", paddingLeft: "25px" }}
          >
            Accomplishments
          </Typography>
          <ul>
            {converHTMLToPlain(data.accomplishments)
              .split(".")
              .map((item) => item.trim())
              .filter((item) => item !== "")
              .map((item, index) => (
                <li key={index}>{item}</li>
              ))}
          </ul>
        </Box>
      </Box>
      <Box>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            marginTop: "0.75vw",
            fontSize: "1vw",
          }}
        >
          <Typography
            gutterBottom
            sx={{ fontSize: "1.5vw", paddingTop: "10px" }}
          >
            Performance Evaluation
          </Typography>
          <Typography gutterBottom sx={{ fontSize: "1.25vw", padding: "10px" }}>
            {userRole != "user" && <img
              src={Edit_icon}
              width={"25px"}
              height={"25px"}
              onClick={() => EditContents("PerformanceEval")}
              className="cursor"
            />}
          </Typography>
        </Box>
        <Grid
          container
          spacing={2}
          sx={{
            width: "74vw",
            paddingRight: "10px",
            marginLeft: "0px",
            border: "1px solid black",
            marginTop: "0px",
          }}
        >
          {Object.keys(data.performance_evaluation || {}).map((main_key, index) => (
            <Grid item xs={12} sm={6} key={main_key}>
              <Typography gutterBottom sx={{ fontSize: "1vw" }}>
                {main_key}
              </Typography>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "space-between",
                }}
              >
                {Object.keys(data.performance_evaluation[main_key]).map(
                  (item, index) => (
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "space-between",
                      }}
                      key={item}
                    >
                      <div
                        style={{
                          fontSize: ".9vw",
                          margin: "0px",
                          display: "flex",
                          alignItems: "center",
                        }}
                      >
                        {item}
                      </div>
                      <div
                        style={{
                          width: "12.2vw",
                          display: "flex",
                          justifyContent: "left",
                          alignItems: "center",
                          fontSize: ".9vw",
                          margin: "0px",
                        }}
                      >
                        <Checkbox checked />
                        {data.performance_evaluation[main_key][item]}
                      </div>
                    </div>
                  )
                )}
              </div>
            </Grid>
          ))}
        </Grid>
        <Box>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              marginTop: "0.75vw",
              fontSize: "1vw",
            }}
          >
            <Typography
              gutterBottom
              sx={{ fontSize: "1.5vw", paddingTop: "10px" }}
            >
              Comments and Goals
            </Typography>
            <Typography
              gutterBottom
              sx={{ fontSize: "1.25vw", padding: "10px" }}
            >
              <img
                src={Edit_icon}
                width={"25px"}
                height={"25px"}
                onClick={() => EditContents("Comments")}
                className="cursor"
              />
            </Typography>
          </Box>
          <Box sx={{ border: "1px solid black" }}>
            <Typography
              gutterBottom
              sx={{
                fontSize: "1.25vw",
                paddingTop: "25px",
                paddingLeft: "25px",
              }}
            >
              Comments
            </Typography>
            <p style={{ fontSize: "1.2vw", paddingLeft: "25px" }}>
              {converHTMLToPlain(data.comments)
                .split(".")
                .map((item) => item.trim())
                .filter((item) => item !== "")
                .map((item, index) => (
                  <li key={index}>{item}</li>
                ))}
            </p>
            <Typography
              gutterBottom
              sx={{
                fontSize: "1.25vw",
                paddingTop: "10px",
                paddingLeft: "25px",
              }}
            >
              Goals
            </Typography>
            <ul>
              {converHTMLToPlain(data.goals_for_next_review)
                .split(".")
                .map((item) => item.trim())
                .filter((item) => item !== "")
                .map((item, index) => (
                  <li style={{ fontSize: "1.2vw" }} key={index}>
                    {item}
                  </li>
                ))}
            </ul>
          </Box>
        </Box>
        <Box>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              marginTop: "0.75vw",
              fontSize: "1vw",
            }}
          >
            <Typography
              gutterBottom
              sx={{ fontSize: "1.5vw", paddingTop: "10px" }}
            >
              Overall Rating
            </Typography>
            <Typography
              gutterBottom
              sx={{ fontSize: "1.25vw", padding: "10px" }}
            >
              {userRole != "user" && <img
                src={Edit_icon}
                width={"25px"}
                height={"25px"}
                onClick={() => EditContents("Comments")}
                className="cursor"
              />}
            </Typography>
          </Box>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              fontSize: "1vw",
              border: "1px solid black",
            }}
          >
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <Checkbox checked={true} /> {data.overall_ratings}
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                paddingRight: "10px",
              }}
            >
              Submission Date : {data.submission_date}
            </div>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default OverallPerformanceReview;
