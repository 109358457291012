import { Grid, Card, CardContent, Typography, CardMedia } from "@mui/material"; // Import Material-UI components
import "../../styling/training/CourseEnrollment.css";
import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { getHeaders } from "../utils/useAuth";
import axiosInstance from "../../utils/axiosInstance";

export default function CourseEnrollment({
  setShowThumbnail,
  getCourseDetails,
  setStartCourse,
  setEnrollCourse,
}) {
  const selectCourse = (course) => {
    setShowThumbnail(true);
    var courseStatus = course["course_status"] || course["Course_status"];

    // already course is assigned
    if (
      courseStatus === "Complete" ||
      courseStatus === "In Progress" ||
      courseStatus === "Pending"
    ) {
      setStartCourse(true);
      setEnrollCourse(false);
    } else {
      setStartCourse(false);
      setEnrollCourse(true);
    }

    console.log(
      "course details are = ",
      course["Course_id"],
      " and ",
      course["Course_title"],
      course["Course_banner"]
    );
    getCourseDetails(
      course["Course_id"] || course["course_id"],
      course["Course_title"] || course["course_title"],
      course["Course_banner"] || course["course_banner"]
    );
  };
  const navigate = useNavigate();
  const [token, setToken] = useState();
  const [CoursesList, setCoursesList] = useState();

  useEffect(() => {
    const auth_token = sessionStorage.getItem("token");
    console.log("auth-tokenis :", auth_token);
    if (!auth_token) navigate("/login");
    else setToken(auth_token);
  }, []);

  useEffect(() => {
    if (token) {
      axiosInstance
        .get(`/courses/stat`)
        .then((res) => {
          console.log("new courses API: ", res.data);

          const approvedCourses = res.data.filter(
            (course) => course.status === "Approved"
          );

          // Filter - remove courses that are already in CoursesList
          axiosInstance.get(`/training/`).then((trainingRes) => {
            const trainingCourses = trainingRes.data.map(
              (course) => course.Course_id || course.course_id
            );

            const newCourses = approvedCourses.filter(
              (course) =>
                !trainingCourses.includes(course.Course_id || course.course_id)
            );

            setCoursesList(newCourses);
          });
        })
        .catch((err) => {
          console.log("there was error: ", err);
        });
    }
  }, [token]);

  return (
    <div className="assigned-courses">
      {/* <h1>Assigned Courses</h1> */}
      {/* Use Grid container for horizontal scrolling */}
      <Grid
        container
        className="courses-list"
        spacing={2}
        wrap="nowrap" // Ensure items do not wrap to the next line
        sx={{
          overflowX: "auto",
          flexWrap: "nowrap",
          scrollBehavior: "smooth",
          maxWidth: "94vw",
          // overflowX: "auto",
          // overflowY: "hidden", // Hide vertical scrollbar
          // maxWidth: "100%",
          // paddingBottom: "16px", // Adjust padding to avoid unnecessary bottom space
        }}
      >
        {CoursesList &&
          CoursesList.map((course, index) => (
            <Grid item key={index}>
              {/* Use Material-UI Card component for each course */}
              <Card
                onClick={() => selectCourse(course)}
                sx={{
                  width: "17vw",
                  minHeight: "14.5vw",
                  maxHeight: "14.5vw",
                  margin: "0 0",
                  flex: "0 0 auto",
                  borderRadius: "1vw",
                }}
                // sx={{ width: 300, margin: "0 0", flex: "0 0 auto" }} // Adjust width and margin for each card
              >
                <CardMedia
                  component="img"
                  // height="140"
                  image={course["Course_banner"] || course["course_banner"]}
                  alt="course-thumbnail-img"
                  sx={{ minHeight: "10vw", maxHeight: "10vw", width: "17vw" }}
                />
                <CardContent>
                  <Typography
                    // variant="h6"
                    component="div"
                    dangerouslySetInnerHTML={{
                      __html: course["Course_title"] || course["course_title"],
                    }}
                    sx={{ fontSize: "1vw" }}
                  ></Typography>
                </CardContent>
              </Card>
            </Grid>
          ))}
      </Grid>
    </div>
  );
}
