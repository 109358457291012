import "../../../../styling/admin/AdQuestionsPreview.css";
import { useState, useEffect } from "react";
import axiosInstance from "../../../../utils/axiosInstance";
import ConfirmationPopup from "../../../../utils/confirmationPopup";
import AlertMessage from "../../../../utils/alertMessage";
export default function AdQuestionsPreview(props) {
  var { courseId, moduleId, lessonId } = props;
  const [existingLesson, setExistingLesson] = useState(false);
  const [sampleQuestionsSet, setSampleQuestionsSet] = useState([]);
  const [role, setRole] = useState("");
  const [confirmState, setConfirmState] = useState({
    open: false,
    message: "",
    onConfirm: () => {},
  });
  const [alertState, setAlertState] = useState({
    open: false,
    message: "",
    variant: "success",
  });

  useEffect(() => {
    const userRole = sessionStorage.getItem("role");
    setRole(userRole);
  }, []);

  useEffect(() => {
    if (lessonId !== null) {
      setExistingLesson(true);
    }
  }, [lessonId]);

  useEffect(() => {
    if (existingLesson) {
      axiosInstance
        .get(`/questions/` + lessonId + "/all")
        .then((res) => {
          console.log("Question data received", res.data);
          setSampleQuestionsSet(res.data);
        })
        .catch((err) => {
          console.log("Error in retrieving question data", err);
        });
    }
  }, [existingLesson, lessonId]);

  const handleEdit = (question) => {
    const confirmationMessage = "Edit the question?";

    const onConfirm = () => {
      console.log("Editing question:", question);
      props.onEditQuestion(question);
      setConfirmState({ ...confirmState, open: false });
    };

    setConfirmState({
      open: true,
      message: confirmationMessage,
      onConfirm: onConfirm,
    });
  };

  const handleDelete = (questionId) => {
    const confirmationMessage = "Delete the question?";

    const onConfirm = async () => {
      try {
        const res = await axiosInstance.delete(`/questions/` + questionId);
        console.log("Question deleted successfully", res);
        setSampleQuestionsSet((prevQuestions) =>
          prevQuestions.filter((question) => question.id !== questionId)
        );
        setAlertState({
          open: true,
          message: "Question deleted successfully!",
          variant: "success",
        });
      } catch (err) {
        console.log("Error deleting the question", err);
        setAlertState({
          open: true,
          message: "Error deleting question.",
          variant: "error",
        });
      }
      setConfirmState({ ...confirmState, open: false });
    };

    setConfirmState({
      open: true,
      message: confirmationMessage,
      onConfirm: onConfirm,
    });
  };

  return (
    <div className="que-preview-bg">
      {sampleQuestionsSet.map((question, index) => (
        <div className="ind-que" key={question.id}>
          <div className="hor-flex">
            <div
              className="ind-que-stat"
              dangerouslySetInnerHTML={{
                __html: `Q.${index + 1} ${question.question}`,
              }}
            />
            {!(role === "user" || role === "manager") && (
              <button
                className="ind-que-button-edit"
                onClick={() => handleEdit(question)}
                disabled={role === "user" || role === "manager"}
              >
                
              </button>
            )}
            {!(role === "user" || role === "manager") && (
              <button
                className="ind-que-button-del"
                onClick={() => handleDelete(question.id)}
                disabled={role === "user" || role === "manager"}
              >
                
              </button>
            )}
          </div>
          {question.options.map((option, idx) => (
            <div
              key={idx}
              className="ind-que-opt"
              dangerouslySetInnerHTML={{
                __html: `Option ${idx + 1}: ${option}`,
              }}
            />
          ))}
          {(role === "admin" || role === "editor") && (
            <div
              className="ind-que-ans"
              dangerouslySetInnerHTML={{
                __html: `Correct Answer: ${question.answers}`,
              }}
            />
          )}
        </div>
      ))}
      {/* Confirmation Popup */}
      {confirmState.open && (
        <ConfirmationPopup
          message={confirmState.message}
          onConfirm={() => {
            confirmState.onConfirm();
            setConfirmState({ ...confirmState, open: false });
          }}
          onCancel={() => setConfirmState({ ...confirmState, open: false })}
          onClose={() => setConfirmState({ ...confirmState, open: false })}
        />
      )}

      {/* Single AlertMessage Component */}
      {alertState.open && (
        <AlertMessage
          message={alertState.message}
          variant={alertState.variant}
          onClose={() => setAlertState({ ...alertState, open: false })}
        />
      )}
    </div>
  );
}
