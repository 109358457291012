import axios from "axios";
import { getItem, setItem } from "./storage";

const axiosInstance = axios.create({
  baseURL: process.env.REACT_APP_BACKEND_URL,
  timeout: 10000, // Example timeout
});

// Refresh token function
const refreshToken = async () => {
  try {
    // Get the current refresh token
    const refreshToken = getItem("refreshToken");

    // Construct the URL with query parameters
    const url = new URL(
      `${process.env.REACT_APP_BACKEND_URL}/auth/refresh_token`
    );
    url.searchParams.append("refresh_token", refreshToken);

    // Make the request to refresh the token
    const response = await axios.post(
      url.toString(),
      null // No body required for this request
      // {
      //   params: {
      //     refresh_token: refreshToken,
      //   },
      // }
    );

    console.log("===============");
    console.log("response from refresh token ");
    console.log("check if we are using the same response token");
    console.log(response);
    console.log("===============");

    // Store new tokens
    setItem("token", response.data.access_token);
    // setItem("refreshToken", response.data.refreshToken);

    return response.data.token;
  } catch (error) {
    console.error("Failed to refresh token", error);
    // Log out the user if refresh token fails
    sessionStorage.removeItem("token");
    sessionStorage.removeItem("refreshToken");
    sessionStorage.removeItem("role");
    sessionStorage.removeItem("permissions");
    !window.location.href.includes("/login") && (window.location.href = "/login");
    return null;
  }
};

// Add a request interceptor
axiosInstance.interceptors.request.use(
  async (config) => {
    let token = getItem("token");
    if (token) {
      // Check if the token is about to expire in the next 5 minutes
      if (!isTokenValid(token, 1 * 60)) {
        token = await refreshToken();
        console.log("token recieved from refresh token");
        console.log(token);
        console.log("this is from refresh token");
      }
      if (token) {
        config.headers.Authorization = `Bearer ${token}`;
      }
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

// Add a response interceptor
axiosInstance.interceptors.response.use(
  (response) => {
    return response;
  },
  async (error) => {
    const originalRequest = error.config;
    if (
      error.response &&
      error.response.status === 401 &&
      !originalRequest._retry
    ) {
      originalRequest._retry = true;
      const token = await refreshToken();
      if (token) {
        originalRequest.headers.Authorization = `Bearer ${token}`;
        return axiosInstance(originalRequest);
      }
    }
    return Promise.reject(error);
  }
);

export const isTokenValid = (token, buffer = 0) => {
  if (!token) {
    return false;
  }

  try {
    const [header, payload, signature] = token.split(".");
    const decodedPayload = JSON.parse(atob(payload));
    const expirationTimestamp = decodedPayload.exp;
    const now = Math.floor(Date.now() / 1000);
    return expirationTimestamp >= now + buffer;
  } catch (err) {
    console.error("Invalid token:", err);
    return false;
  }
};

export default axiosInstance;
