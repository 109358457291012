import React, { useState, useEffect, useRef } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import axiosInstance from "../utils/axiosInstance";
import Button from "@mui/material/Button";
import "../styling/admin/AdCreateUserProfile.css"; // Import existing CSS
import AdminLeftSideBar from "../components/admin/common/AdLeftSideBar";
import AdminNavbar from "../components/admin/common/AdNavbar";
import CameraIcon from "../images/CameraIcon.png";
import { getHeaders } from "../components/utils/useAuth";
import AlertMessage from "../utils/alertMessage";
import ConfirmationPopup from "../utils/confirmationPopup";
import { isValidPhoneNumber, isValidCompanyEmail } from "../utils/validator";
import DP from "../images/man.png";
const CreateUserProfile = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [alertState, setAlertState] = useState({
    open: false,
    message: "",
    variant: "success",
  });

  const [confirmState, setConfirmState] = useState({
    open: false,
    message: "",
    onConfirm: () => { },
  });

  const [userID, setUserID] = useState(location.state?.userID);
  const [name, setName] = useState("John Doe");
  const [gender, SetGender] = useState("Default");
  const [phone, setPhone] = useState("1234567890");
  const [email, setEmail] = useState("user@copperpoddigital.com");
  const [username, setUserName] = useState("CPD_");
  const [password, setPassWord] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [role, setRole] = useState("user");
  const [designation, setDesignation] = useState("software developer");
  const [specialPermission, setSpecialPermission] = useState("normal");
  const [profilePic, setProfilePic] = useState();
  const [logUserRole, setLogUserRole] = useState("");
  const [isDirty, setIsDirty] = useState(false);
  const [errorState, setErrorState] = useState({
    name: "",
    gender: "",
    email: "",
    phone: "",
    profilePic: "",
    username: "",
    password: "",
    role: "",
    designation: "",
    specialPermission: "",
  });

  const roleOptions = ["editor", "admin", "user"];
  const genderOptions = ["Male", "Female", "Other"];
  const specialPermissionOptions = ["super", "hyper", "normal"];
  const userNameFileInput = useRef(null);

  useEffect(() => {
    const token = sessionStorage.getItem("token");
    if (!token) {
      navigate("/login");
    }
  }, [navigate]);

  useEffect(() => {
    const userRole = sessionStorage.getItem("role");
    setLogUserRole(userRole);
  }, []);

  useEffect(() => {
    if (userID) {
      axiosInstance
        .get(`/users/${userID}`)
        .then((res) => {
          const {
            first_name,
            last_name,
            gender,
            email,
            phone,
            empname,
            designation,
            role,
            profile_picture,
            // password,
            special_permissions,
          } = res.data;

          setName(`${first_name} ${last_name}`);
          SetGender(gender);
          setEmail(email);
          setPhone(phone);
          setUserName(empname);
          setDesignation(designation);
          setRole(role);
          setSpecialPermission(special_permissions || "normal");
          setProfilePic(profile_picture);
          setPassWord("");
        })
        .catch((err) => {
          console.error("Error fetching user data:", err);
        });
    }
  }, [userID]);

  const handleUserNameUpload = () => {
    userNameFileInput.current.click();
  };

  const handleUserProfileChange = (event) => {
    const fileUploaded = event.target.files[0];
    const reader = new FileReader();
    reader.onload = () => {
      setProfilePic(reader.result);
      setIsDirty(true);
    };
    reader.readAsDataURL(fileUploaded);
  };

  const resetState = () => {
    setName("John Doe");
    setPhone("1234567890");
    setEmail("john.doe10@xyx.com");
    setUserName("myusername");
    setRole("user");
    setSpecialPermission("normal");
    setProfilePic(null);
    setPassWord("");
    SetGender("Default");
  };

  const createUser = (payload) => {
    axiosInstance
      .post(`/users/`, payload)
      .then((res) => {
        setAlertState({
          open: true,
          message: "User created successfully!",
          variant: "success",
        });
        setTimeout(() => {
          navigate("/user-management");
        }, 1000);
      })
      .catch((err) => {
        console.error("Error saving profile:", err);
        setAlertState({
          open: true,
          message: "Error creating user.",
          variant: "error",
        });
      });
  };

  const updateUser = (userID, payload) => {
    axiosInstance
      .put(`/users/${userID}`, payload)
      .then((res) => {
        setAlertState({
          open: true,
          message: "User updated successfully!",
          variant: "success",
        });
        setTimeout(() => {
          navigate("/user-management");
        }, 1000);
      })
      .catch((err) => {
        console.error("Error updating profile:", err);
        setAlertState({
          open: true,
          message: "Error updating user.",
          variant: "error",
        });
      });
  };

  const saveProfileChanges = (e) => {
    e.preventDefault();

    // if (!isDirty) {
    //   return;
    // }

    // // Check for empty fields and construct error message
    // const emptyFields = [];
    // if (!name) emptyFields.push("Name");
    // if (!email) emptyFields.push("Email");
    // if (!phone) emptyFields.push("Phone Number");
    // if (!profilePic) emptyFields.push("Profile picture");
    // if (!username) emptyFields.push("Username");
    // if (!userID) {
    //   if (!password) emptyFields.push("Password");
    // }
    // if (!role) emptyFields.push("role");
    // if (!specialPermission) emptyFields.push("permissions");

    // if (emptyFields.length > 0) {
    //   setAlertState({
    //     open: true,
    //     message: `${emptyFields.join(", ")} cannot be empty.`,
    //     variant: "error",
    //   });
    //   return;
    // }

    // if ((userID && phone !== "") || !userID) {
    // if (!isValidPhoneNumber(phone.toString())) {
    //   // Validate phone number
    //   setAlertState({
    //     open: true,
    //     message: "Please enter a valid phone number.",
    //     variant: "error",
    //   });
    //   return;
    // }

    // if (!isValidCompanyEmail(email)) {
    //   setAlertState({
    //     open: true,
    //     message: "Please enter a valid organisation email id.",
    //     variant: "error",
    //   });
    //   return;
    // }
    // }

    const newErrorState = {
      name: "",
      email: "",
      phone: "",
      profilePic: "",
      username: "",
      password: "",
      role: "",
      specialPermission: "",
    };

    if (!name) newErrorState.name = "Name cannot be empty.";
    if (!email) newErrorState.email = "Email cannot be empty.";
    if (!phone) newErrorState.phone = "Phone Number cannot be empty.";
    // if (!profilePic)
    //   newErrorState.profilePic = "Profile picture cannot be empty.";
    if (!username) newErrorState.username = "Username cannot be empty.";
    // if (!password) newErrorState.password = "Password cannot be empty.";
    // if (userID === undefined || userID === "") {
    //   // Check password only if userID is not defined (i.e., creating a new user)
    //   if (!password) newErrorState.password = "Password cannot be empty.";
    // }
    if (!userID) {
      if (!password) newErrorState.password = "Password cannot be empty.";
      if (password && password.length < 8) newErrorState.password = "Password must be at least 8 characters long.";
    }
    if (!role) newErrorState.role = "Role cannot be empty.";
    if (!specialPermission)
      newErrorState.specialPermission = "Special permission cannot be empty.";

    if (!isValidPhoneNumber(phone.toString())) {
      newErrorState.phone = "Please enter a valid phone number.";
    }

    if (!isValidCompanyEmail(email)) {
      newErrorState.email = "Please enter a valid organization email.";
    }

    setErrorState(newErrorState);

    // Check if any errors exist
    if (Object.values(newErrorState).some((error) => error !== "")) {
      setAlertState({
        open: true,
        message: "Please enter the required fields before submitting.",
        variant: "error",
      });
      return;
    }

    let confirmationMessage = userID
      ? "Are you sure you want to update the user?"
      : "Are you sure you want to save the user?";

    const handleConfirm = () => {
      const randomEmployeeId = Math.floor(Math.random() * 10000) + 1;

      const payload = {
        first_name: name.split(" ")[0],
        last_name: name.split(" ").slice(1).join(" "),
        empname: username,
        profile_picture: profilePic
          ? profilePic
          : "https://cpdpeopeportal.s3.ap-south-1.amazonaws.com/profile_pictures/default.png",
        role,
        special_permissions: specialPermission,
        email,
        phone: phone.toString(),
        official_employee_id: randomEmployeeId.toString(),
        gender: gender,
        designation: designation,
      };

      if (isDirty && password) {
        payload.password = password;
      }

      if (userID) {
        updateUser(userID, payload);
      } else {
        createUser(payload);
      }
    };

    setConfirmState({
      open: true,
      message: confirmationMessage,
      onConfirm: handleConfirm,
    });
  };

  const cancelProfileChanges = () => {
    const confirmationMessage = "Are you sure you want to delete the profile?";

    const handleConfirm = () => {
      if (userID) {
        axiosInstance
          .delete(`/users/${userID}`)
          .then((res) => {
            setAlertState({
              open: true,
              message: "User deleted successfully!",
              variant: "success",
            });
            setTimeout(() => {
              navigate("/user-management");
            }, 1000);
          })
          .catch((err) => {
            console.error("Error deleting user:", err);
            setAlertState({
              open: true,
              message: "Error deleting user.",
              variant: "error",
            });
          });
      }
      resetState();
    };

    setConfirmState({
      open: true,
      message: confirmationMessage,
      onConfirm: handleConfirm,
    });
  };

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const handleBeforeUnload = (e) => {
    if (isDirty) {
      e.preventDefault();
      e.returnValue =
        "You have unsaved changes, are you sure you want to leave?";
    }
  };

  useEffect(() => {
    window.addEventListener("beforeunload", handleBeforeUnload);
    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, [isDirty]);

  return (
    <div className="create-user-profile-bg">
      <AdminLeftSideBar />
      <div className="right-section">
        <AdminNavbar />
        <h1>User Profile</h1>
        <div className="user-info-outer-cont">
          <div className="user-info-header">
            <span>User Information</span>
            {logUserRole === "admin" && (
              <>
                <Button
                  className="cancel"
                  onClick={cancelProfileChanges}
                  variant="contained"
                  style={{ visibility: userID == null ? "hidden" : "visible" }}
                >
                  Delete
                </Button>

                <Button
                  onClick={saveProfileChanges}
                  variant="contained"
                  className="save"
                >
                  Save
                </Button>
              </>
            )}
          </div>
          <div className="user-info-body">
            <div className="user-info-body-left-cont">
              <div>
                <img
                  className={`${profilePic ? "uploadedUserProfile" : "camera-icon"
                    }`}
                  src={profilePic ? profilePic : DP}
                  alt="Profile"
                />
                {logUserRole === "admin" && (
                  <>
                    <Button
                      onClick={handleUserNameUpload}
                      variant="contained"
                      className="upload-profile-button"
                    >
                      Upload Profile Image
                    </Button>
                    <input
                      type="file"
                      onChange={handleUserProfileChange}
                      ref={userNameFileInput}
                      style={{ display: "none" }}
                    />
                  </>
                )}
                {/* <label className="error-label">{errorState.profilePic}</label> */}
              </div>
            </div>
            <div className="user-info-body-mid-cont">
              <div>
                <div className="labels">
                  <label>Name*</label>
                  <label className="error-label">{errorState.name}</label>
                </div>
                <input
                  type="text"
                  value={name}
                  onChange={(e) => {
                    setName(e.target.value);
                    setIsDirty(true);
                  }}
                  disabled={logUserRole !== "admin"}
                />
              </div>

              <div>
                <div className="labels">
                  <label>Gender</label>
                  <label className="error-label">{errorState.gender}</label>
                </div>
                <select
                  value={gender}
                  onChange={(e) => {
                    SetGender(e.target.value);
                    setIsDirty(true);
                  }}
                  disabled={logUserRole !== "admin"}
                >
                  {genderOptions.map((option) => (
                    <option key={option} value={option}>
                      {option}
                    </option>
                  ))}
                </select>
              </div>

              <div>
                <div className="labels">
                  <label>Phone Number*</label>
                  <label className="error-label">{errorState.phone}</label>
                </div>
                <input
                  type="number"
                  value={phone}
                  onChange={(e) => {
                    setPhone(e.target.value);
                    setIsDirty(true);
                  }}
                  disabled={logUserRole !== "admin"}
                />
              </div>

              <div>
                <div className="labels">
                  <label>Username*</label>
                  <label className="error-label">{errorState.username}</label>
                </div>
                <input
                  type="text"
                  value={username}
                  onChange={(e) => {
                    setUserName(e.target.value);
                    setIsDirty(true);
                  }}
                  disabled={logUserRole !== "admin"}
                />
              </div>

              <div>
                <div className="labels">
                  <label>Password*</label>
                  <label className="error-label">{errorState.password}</label>
                </div>
                <div className="show-pwd">
                  <input
                    type={showPassword ? "text" : "password"}
                    value={password}
                    onChange={(e) => {
                      setPassWord(e.target.value);
                      setIsDirty(true);
                    }}
                    disabled={logUserRole !== "admin"}
                  />
                  <Button
                    type="button"
                    onClick={togglePasswordVisibility}
                    className="toggle-password-button"
                    disabled={logUserRole !== "admin"}
                  >
                    {showPassword ? "Hide" : "Show"}
                  </Button>
                </div>
              </div>
            </div>
            <div className="user-info-body-right-cont">
              <div>
                <div className="labels">
                  <label>Email-ID*</label>
                  <label className="error-label">{errorState.email}</label>
                </div>
                <input
                  type="text"
                  value={email}
                  onChange={(e) => {
                    setEmail(e.target.value);
                    setIsDirty(true);
                  }}
                  disabled={logUserRole !== "admin"}
                />
              </div>
              <div>
                <div className="labels">
                  <label>Designation</label>
                  <label className="error-label">
                    {errorState.designation}
                  </label>
                </div>
                <input
                  type="text"
                  value={designation}
                  onChange={(e) => {
                    setDesignation(e.target.value);
                    setIsDirty(true);
                  }}
                  disabled={logUserRole !== "admin"}
                />
              </div>
              <div>
                <div className="labels">
                  <label>Role*</label>
                  <label className="error-label">{errorState.role}</label>
                </div>
                <select
                  value={role}
                  onChange={(e) => {
                    setRole(e.target.value);
                    setIsDirty(true);
                  }}
                  disabled={logUserRole !== "admin"}
                >
                  {roleOptions.map((option) => (
                    <option key={option} value={option}>
                      {option}
                    </option>
                  ))}
                </select>
              </div>
              <div>
                <div className="labels">
                  <label>Special Permission*</label>
                  <label className="error-label">
                    {errorState.specialPermission}
                  </label>
                </div>
                <select
                  value={specialPermission}
                  onChange={(e) => {
                    setSpecialPermission(e.target.value);
                    setIsDirty(true);
                  }}
                  disabled={logUserRole !== "admin"}
                >
                  {specialPermissionOptions.map((option) => (
                    <option key={option} value={option}>
                      {option}
                    </option>
                  ))}
                </select>
              </div>
            </div>
          </div>
        </div>

        {/* Confirmation Popup */}
        {confirmState.open && (
          <ConfirmationPopup
            message={confirmState.message}
            onConfirm={() => {
              confirmState.onConfirm();
              setConfirmState({ ...confirmState, open: false });
            }}
            onCancel={() => setConfirmState({ ...confirmState, open: false })}
            onClose={() => setConfirmState({ ...confirmState, open: false })}
          />
        )}

        {/* Single AlertMessage Component */}
        {alertState.open && (
          <AlertMessage
            message={alertState.message}
            variant={alertState.variant}
            onClose={() => setAlertState({ ...alertState, open: false })}
          />
        )}
      </div>
    </div>
  );
};

export default CreateUserProfile;
