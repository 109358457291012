import "../../../styling/admin/AdCourseCards.css";
import courseBG from "../../../images/courseBG-image.png";
import AddCourseBtnIcon from "../../../images/admin/add_course_btn_icon.png";
import { useNavigate } from "react-router-dom";
import { generateUniqueId } from "../../utils";
import { useEffect, useRef, useState } from "react";
import { getHeaders } from "../../utils/useAuth";
import axiosInstance from "../../../utils/axiosInstance";

const statusColors = {
  Approved: "green",
  // Not Approved: "red",
};

export default function CourseCards({ status }) {
  const [newCards, setNewCards] = useState([]); // Use state to store newCards
  const [role, setRole] = useState("");

  useEffect(() => {
    const userRole = sessionStorage.getItem("role");
    setRole(userRole);
  }, []);

  // load course data
  useEffect(() => {
    axiosInstance
      .get(`/courses/stat`)
      .then((res) => {
        console.log("courses stat resposne:", res.data);
        // newCardsRef.current = res.data;

        setNewCards(res.data);
        console.log("========================================");
        console.log("Response recieved from course stat api", res.data);
        console.log("status came from tab", status);
        console.log("========================================");
      })
      .catch((error) => {
        console.log("error while fetching course data :", error);
      });
  }, []);

  console.log("status came from tab", status);
  const filteredCards =
    status === "All"
      ? newCards
      : newCards.filter((newCards) => newCards.status === status);

  console.log("value of filtered cards after filter");
  console.log(filteredCards);
  const navigate = useNavigate();

  // adding new course
  const addCourse = (courseId) => {
    navigate("/course-creation", { state: { courseId: courseId } });
    // navigate("/course-creation", { state: { courseId: generateUniqueId() } });
  };

  return (
    <div className="all-cards">
      {/* Single Card */}
      {filteredCards.map((card) => (
        <div className="all-cards-bg" onClick={() => addCourse(card.course_id)}>
          <div className="cards-course-cover">
            <img src={card.course_banner} alt="courseBG" />
          </div>
          <div className="cards-course-metadata">
            {/* <span>Course Name</span> */}
            {/* <span>{card.course_title}</span> */}
            <span
              dangerouslySetInnerHTML={{ __html: card.course_title }}
            ></span>
            <span>Modules : {card.total_modules}</span>
            <span>lessons : {card.total_lessons}</span>
            <span>
              status :{" "}
              <span style={{ color: statusColors[card.status] || "red" }}>
                {card.status}
              </span>
            </span>
          </div>
        </div>
      ))}

      {/* Last Add Course Card */}
      {status === "All" && (role === "admin" || role === "editor") && (
        <div className="add-course-card-bg" onClick={() => addCourse(null)}>
          <img src={AddCourseBtnIcon} alt="add course button icon" />
          <span>Add Course</span>
        </div>
      )}
    </div>
  );
}
