import React, { useState, useRef, useEffect } from "react";
import Editor from "react-simple-wysiwyg";
import CameraIcon from "../../../../images/CameraIcon.png";
import { useGlobalState } from "../../../GlobalStateContext";
import axiosInstance from "../../../../utils/axiosInstance";
import "../../../../styling/admin/AdAddModule.css";
import { getHeaders } from "../../../utils/useAuth";
import AlertMessage from "../../../../utils/alertMessage";
import ConfirmationPopup from "../../../../utils/confirmationPopup";

export default function AddModule(props) {
  const [alertState, setAlertState] = useState({
    open: false,
    message: "",
    variant: "success",
  });

  const [confirmState, setConfirmState] = useState({
    open: false,
    message: "",
    onConfirm: () => {},
  });

  const { courseId, moduleId } = props;
  const [role, setRole] = useState("");

  useEffect(() => {
    const userRole = sessionStorage.getItem("role");
    setRole(userRole);
  }, []);

  const initialState = {
    id: 0,
    course_id: 0,
    title: "",
    description: "",
    module_pass_pct: "",
  };
  const [modState, setModState] = useState(initialState);
  const [existingModule, setExistingModule] = useState(false);
  const hiddenFileInput1 = useRef(null);

  useEffect(() => {
    console.log("current state of existing module id ", existingModule);
    console.log("module id recieved is ", moduleId);
    if (moduleId !== null) {
      setExistingModule(true);
      fetchModuleDetails();
    } else {
      setExistingModule(false);
      setModState(initialState);
    }
    console.log("after state of existing module id ", existingModule);
    console.log("module id clicked is ", moduleId);
  }, [moduleId]);

  const fetchModuleDetails = async () => {
    try {
      const res = await axiosInstance.get(`/modules/` + moduleId);
      const sampleMod = res.data;
      sampleMod.module_pass_pct = sampleMod.module_pass_pct.toString();
      setModState(sampleMod);
      props.fetchModules();
    } catch (error) {
      console.error("Error fetching module data:", error);
      setAlertState({
        open: true,
        message: "Error fetching module data",
        variant: "error",
      });
    }
  };

  const onModuleSave = async () => {
    try {
      const newModule = {
        id: moduleId,
        course_id: courseId,
        title: modState.title,
        description: modState.description,
        module_pass_pct: modState.module_pass_pct,
      };
      const confirmationMessage = existingModule
        ? "Update the " + newModule.title + " module?"
        : "Save the " + newModule.title + " module?";

      setConfirmState({
        open: true,
        message: confirmationMessage,
        onConfirm: async () => {
          if (!existingModule) {
            await createModule(newModule);
          } else {
            await updateModule(newModule);
          }
          props.fetchModules();
          setAlertState({
            open: true,
            message: "Module saved successfully",
            variant: "success",
          });
          if (!existingModule) {
            // Reset state to initial values
            setModState(initialState);
            setExistingModule(false);
            props.fetchModules(); // You may want to fetch modules again if necessary
          }
        },
      });
    } catch (error) {
      console.error("Error saving module:", error);
      setAlertState({
        open: true,
        message: "Error saving module",
        variant: "error",
      });
    }
  };

  const createModule = async (newModule) => {
    const res = await axiosInstance.post(`/modules/`, newModule);
    console.log("Module created:", res.data);
  };

  const updateModule = async (newModule) => {
    const res = await axiosInstance.put(
      `/modules/` + moduleId + "/",
      newModule
    );
    console.log("Module updated:", res.data);
  };

  const deleteModule = () => {
    const confirmationMessage =
      "Are you sure you want to delete: " + modState.title + " module?";

    const handleConfirm = async () => {
      try {
        const res = await axiosInstance.delete(`/modules/` + moduleId + "/");
        console.log("Module deleted:", res.data);
        setModState(initialState);
        setAlertState({
          open: true,
          message: "Module deleted successfully!",
          variant: "success",
        });
        // props.fetchModules();
      } catch (error) {
        console.error("Error deleting module:", error);
        setAlertState({
          open: true,
          message: "Error deleting module",
          variant: "error",
        });
      }
    };

    setConfirmState({
      open: true,
      message: confirmationMessage,
      onConfirm: handleConfirm,
    });
  };

  return (
    <div className="add-module-bg">
      <h5>Module Title</h5>
      <div id="module-title-editor">
        <Editor
          value={modState.title}
          onChange={(e) =>
            setModState((prevState) => ({
              ...prevState,
              title: e.target.value,
            }))
          }
          disabled={role === "user" || role === "manager"}
        />
      </div>
      <h5>Description</h5>
      <div id="module-desc-editor">
        <Editor
          value={modState.description}
          onChange={(e) =>
            setModState((prevState) => ({
              ...prevState,
              description: e.target.value,
            }))
          }
          disabled={role === "user" || role === "manager"}
        />
      </div>
      <h5>Module Passing Criteria</h5>
      <div id="module-pass-criteria">
        <Editor
          value={modState.module_pass_pct}
          onChange={(e) =>
            setModState((prevState) => ({
              ...prevState,
              module_pass_pct: e.target.value,
            }))
          }
          disabled={role === "user" || role === "manager"}
        />
      </div>
      {!(role === "user" || role === "manager") && (
        <div className="module-save-btn">
          <button onClick={onModuleSave}>Save</button>
          <button onClick={deleteModule}>Delete</button>
        </div>
      )}
      {confirmState.open && (
        <ConfirmationPopup
          message={confirmState.message}
          onConfirm={() => {
            confirmState.onConfirm();
            setConfirmState({ ...confirmState, open: false });
          }}
          onCancel={() => setConfirmState({ ...confirmState, open: false })}
          onClose={() => setConfirmState({ ...confirmState, open: false })}
        />
      )}

      {/* Single AlertMessage Component */}
      {alertState.open && (
        <AlertMessage
          message={alertState.message}
          variant={alertState.variant}
          onClose={() => setAlertState({ ...alertState, open: false })}
        />
      )}
    </div>
  );
}
