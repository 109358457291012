import React, { useState, useEffect } from "react";
import { Box, Typography, Grid } from "@mui/material";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css"; // Import Quill styles
import axiosInstance from "../../utils/axiosInstance"; // Assuming axiosInstance is set up for API calls
import "../../styling/appraisal/resp.css";
import { useNavigate } from "react-router-dom";


const ResponsibilitiesForm = ({ validate, employeeId, apprslId }) => {
  // Session check code
  const navigate = useNavigate();

  // if token is expired, navigate to login
  useEffect(() => {
    const token = sessionStorage.getItem("token");
    if (!token) {
      navigate("/login");
    }
  }, []);
  const [responsibilities, setResponsibilities] = useState("");
  const [accomplishments, setAccomplishments] = useState("");

  // Fetch responsibilities and accomplishments on mount if apprslId is available
  useEffect(() => {
    if (apprslId) {
      const fetchResponsibilities = async () => {
        try {
          const res = await axiosInstance.get(
            `/appraisals/responsibilities/${apprslId}`
          );
          if (res.data) {
            setResponsibilities(res.data.responsibilities || "");
            setAccomplishments(res.data.accomplishments || "");
            console.log("Fetched responsibilities data: ", res.data);
          }
        } catch (error) {
          console.error("Error fetching responsibilities:", error);
        }
      };

      fetchResponsibilities();
    }
  }, [apprslId]);

  // Validation logic
  const validateForm = () => {
    // Extract plain text without HTML tags
    const quillResponsibilities = document
      .querySelector(".quill-container .ql-editor")
      .innerText.trim();
    const quillAccomplishments = document
      .querySelectorAll(".quill-container .ql-editor")[1]
      .innerText.trim();

    if (!quillResponsibilities) {
      alert("Please fill Responsibilities field.");
      return false;
    }
    if (!quillAccomplishments) {
      alert("Please fill Accomplishments field.");
      return false;
    }

    console.log("Form validated, updating responsibilities...");
    // updateResponsibilities(); // Call PUT request on successful validation

    const payload = {
      key_responsibilities: responsibilities.trim(),
      accomplishments: accomplishments.trim(),
    };

    axiosInstance
      .put(`/appraisals/${apprslId}`, payload)
      .then((res) => {
        if (res.status >= 200 && res.status < 300) {
          console.log(
            "Successfully updated responsibilities and accomplishments"
          );
        }
      })
      .catch((error) => {
        console.error("Error updating responsibilities:", error);
        return false;
      });

    return true;
  };

  // Set validation function to the passed ref
  useEffect(() => {
    validate.current = validateForm;
  }, [validate, responsibilities, accomplishments]);

  useEffect(() => {
    console.log("employee id received in resp", employeeId);
    console.log("appraisal id received in resp", apprslId);
  }, [apprslId]);

  return (
    <Box className="responsibilities-form">
      <Typography gutterBottom sx={{ fontSize: "1.25vw" }}>
        Responsibilities
      </Typography>

      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Typography
            variant="body1"
            sx={{ fontSize: "1vw", marginBottom: "0.5vw" }}
          >
            Key Responsibilities *
          </Typography>
          <div className="quill-container">
            <ReactQuill
              value={responsibilities}
              onChange={setResponsibilities}
              placeholder="Enter description"
              style={{ height: "100px" }} // Fixed height for the editor
            />
          </div>
        </Grid>

        <Grid item xs={12}>
          <Typography
            variant="body1"
            sx={{ marginTop: "1.5vw", fontSize: "1vw", marginBottom: "0.5vw" }}
          >
            Accomplishments *
          </Typography>
          <div className="quill-container">
            <ReactQuill
              value={accomplishments}
              onChange={setAccomplishments}
              placeholder="Enter description"
              style={{ height: "100px", marginBottom: "1vw" }} // Fixed height for the editor
            />
          </div>
        </Grid>
      </Grid>

      {/* Buttons */}
      <Box
        display="flex"
        justifyContent="flex-end"
        className="buttons-container"
        sx={{ marginTop: "1vw" }}
      >
        {/* Add buttons here if needed */}
      </Box>
    </Box>
  );
};

export default ResponsibilitiesForm;
