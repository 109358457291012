import React, { useState, useEffect } from "react";
import {
  TextField,
  Radio,
  RadioGroup,
  FormControlLabel,
  FormControl,
  FormLabel,
  Grid,
  Box,
  Typography,
  Autocomplete,
} from "@mui/material";
import "../../styling/appraisal/geninfo.css";
import axiosInstance from "../../utils/axiosInstance";
import { useSelector, useDispatch } from "react-redux";
import { setEvalStartDate } from "./AppraisalReducer";
import { styled } from "@mui/material/styles";

const GeneralInformationForm = ({
  validate,
  onAppraisalCreate,
  employeeId,
  apprslId,
}) => {
  const [selectedEmployee, setSelectedEmployee] = useState({
    id: 0,
    name: "",
    designation: "",
  });

  const dispatch = useDispatch();
  const departments = [
    { name: "Client Accounts & Finance" },
    { name: "Engineering" },
    { name: "Finance & Internal Operation" },
    { name: "Founder's Office" },
    { name: "People Success" },
    { name: "Marketing" },
    { name: "Revenue Assurance" },
    { name: "Sales" },
    { name: "IT Administration" },
  ];
  const [selectedDepartment, setSelectedDepartment] = useState(null);
  const [selectedManager, setSelectedManager] = useState(null); // Updated to null
  const [managers, setManagers] = useState([]); // For manager dropdown
  const [reviewDate, setReviewDate] = useState("");
  const [evaluationStartDate, setEvaluationStartDate] = useState("");
  const [reviewType, setReviewType] = useState("");

  // Fetch manager list on component mount
  useEffect(() => {
    axiosInstance
      .get("/organization/manager_all")
      .then((response) => {
        const formattedManagers = response.data.map((manager) => ({
          id: manager.id,
          name: `${manager.first_name} ${manager.last_name} [${manager.email}]`,
        }));
        setManagers(formattedManagers);
      })
      .catch((err) => {
        console.error("Error fetching managers:", err);
      });
  }, []);

  const StyledTextField = styled(TextField)(({ theme }) => ({
    "&.Mui-disabled": {
      color: "#000000", // Change text color
    },
  }));

  // Validation logic
  const validateForm = () => {
    if (!selectedEmployee.name) {
      alert("Employee name is required!");
      return false;
    }
    if (!selectedManager) {
      alert("Manager selection is required!");
      return false;
    }
    if (!reviewDate) {
      alert("Review date is required!");
      return false;
    }
    if (!evaluationStartDate) {
      alert("Evaluation start date is required!");
      return false;
    }
    if (!reviewType) {
      alert("Type of review is required!");
      return false;
    }

    const payload = {
      employee_id: selectedEmployee.id,
      manager_id: selectedManager.id,
      review_date: reviewDate,
      evaluation_start_date: evaluationStartDate,
      type_of_review: reviewType,
      department: selectedDepartment.name,
    };

    if (apprslId) {
      payload.id = apprslId; // Include the appraisal ID for update
    }

    // let success = false;
    let newApprslId = apprslId;

    if (apprslId) {
      // PUT request for updating existing appraisal
      axiosInstance
        .put(`/appraisals/${apprslId}`, payload)
        .then((res) => {
          if (res.status >= 200 && res.status < 300) {
            // success = true;
            onAppraisalCreate(selectedEmployee.id, newApprslId); // Make sure this is being called
          }
        })
        .catch((err) => {
          console.error("Error occurred during appraisal update:", err);
          // success = false;
          // return false;
        });
    } else {
      // POST request for creating a new appraisal
      axiosInstance
        .post("/appraisals/", payload)
        .then((res) => {
          if (res.status >= 200 && res.status < 300 && res.data.id) {
            newApprslId = res.data.id; // Capture the new appraisal ID
            // success = true;
            onAppraisalCreate(selectedEmployee.id, newApprslId); // Make sure this is being called
          } else {
            console.error("Error: POST request did not return an ID.");
          }
        })
        .catch((err) => {
          console.error("Error occurred during appraisal creation:", err);
          // success = false;
          // return false;
        });
    }

    return true;
  };

  useEffect(() => {
    validate.current = validateForm;
  }, [
    validate,
    selectedEmployee,
    selectedManager,
    reviewDate,
    evaluationStartDate,
    reviewType,
  ]);

  // Fetch employee details if apprslId or employeeId is present
  useEffect(() => {
    if (apprslId) {
      // Fetch appraisal data if apprslId is present (for editing)
      axiosInstance
        .get(`/appraisals/${apprslId}`)
        .then((response) => {
          const data = response.data;
          setSelectedEmployee({
            id: data.employee_id,
            name: data.employee_name,
            //department: data.department || "",
            designation: data.designation || "",
          });
          setSelectedManager({
            id: data.manager_id,
            name: data.manager_name,
          });
          setSelectedDepartment({
            name: data.department,
          });
          setReviewDate(data.review_date);
          setEvaluationStartDate(data.evaluation_start_date);
          setReviewType(data.type_of_review);
        })
        .catch((error) => {
          console.error("Error fetching appraisal data:", error);
        });
    } else if (employeeId) {
      // Fetch employee details if apprslId is not present but employeeId is (for new appraisal)
      axiosInstance
        .get(`/users/${employeeId}`)
        .then((response) => {
          const data = response.data;
          setSelectedEmployee({
            id: data.id,
            name: `${data.first_name} ${data.last_name}`,
            // department: data.department || "",
            designation: data.designation || "",
          });
        })
        .catch((error) => {
          console.error("Error fetching employee data:", error);
        });
    }
  }, [apprslId, employeeId]);

  const setStartDate = (date) => {
    setEvaluationStartDate(date);
    dispatch(setEvalStartDate(date));
  };

  return (
    <Box className="general-information-form">
      <Typography
        gutterBottom
        sx={{ fontSize: "1.25vw", paddingBottom: "1vw" }}
      >
        General Information Form
      </Typography>

      <Grid container spacing={2}>
        {/* Employee Name */}
        <Grid item xs={12} sm={4}>
          <TextField
            fullWidth
            label={
              <Typography sx={{ fontSize: "1vw" }}>Employee Name *</Typography>
            }
            value={selectedEmployee.name}
            sx={{
              "& .MuiInputBase-input.Mui-disabled": {
                WebkitTextFillColor: "#000000",
              },
            }}
            placeholder="Enter employee name"
            variant="outlined"
            disabled={!selectedEmployee.name ? false : true}
            size="small"
            onChange={(e) => {
              const value = e.target.value;
              setSelectedEmployee((prev) => ({
                ...prev,
                name: value,
              }));
            }}
          />
        </Grid>

        <Grid item xs={12} sm={4}>
          <Autocomplete
            options={departments}
            getOptionLabel={(option) => option.name}
            value={selectedDepartment || null} // Ensure Autocomplete is set to selected department
            onChange={(event, value) => {
              setSelectedDepartment(value);
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                fullWidth
                label={
                  <Typography sx={{ fontSize: "1vw" }}>Department</Typography>
                }
                placeholder="Select department name"
                variant="outlined"
                size="small"
              />
            )}
          />
        </Grid>

        {/* Designation */}
        <Grid item xs={12} sm={4}>
          <TextField
            fullWidth
            label={
              <Typography sx={{ fontSize: "1vw" }}>Designation *</Typography>
            }
            value={selectedEmployee.designation}
            sx={{
              "& .MuiInputBase-input.Mui-disabled": {
                WebkitTextFillColor: "#000000",
              },
            }}
            placeholder="Designation"
            variant="outlined"
            disabled={!selectedEmployee.designation ? false : true}
            size="small"
            onChange={(e) =>
              setSelectedEmployee((prev) => ({
                ...prev,
                designation: e.target.value,
              }))
            }
          />
        </Grid>

        {/* Manager Selection */}
        <Grid item xs={12} sm={4}>
          <Autocomplete
            options={managers}
            getOptionLabel={(option) => option.name}
            value={selectedManager || null} // Ensure Autocomplete is set to selected manager
            onChange={(event, value) => {
              setSelectedManager(value);
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                fullWidth
                label={
                  <Typography sx={{ fontSize: "1vw" }}>Manager *</Typography>
                }
                placeholder="Select manager name"
                variant="outlined"
                size="small"
              />
            )}
          />
        </Grid>

        {/* Review Date */}
        <Grid item xs={12} sm={4}>
          <TextField
            fullWidth
            label="Review Date *"
            type="date"
            InputLabelProps={{ shrink: true }}
            variant="outlined"
            inputProps={{
              min: new Date().toJSON().slice(0, 10), // Disable all previous dates from the specified min date
            }}
            size="small"
            value={reviewDate}
            onChange={(e) => setReviewDate(e.target.value)}
          />
        </Grid>

        {/* Evaluation Period */}
        <Grid item xs={12} sm={4}>
          <Box display="flex" alignItems="center">
            <TextField
              label="Evaluvation Start Date *"
              type="date"
              InputLabelProps={{ shrink: true }}
              variant="outlined"
              inputProps={{
                min: new Date().toJSON().slice(0, 10), // Disable all previous dates from the specified min date
              }}
              size="small"
              fullWidth
              value={evaluationStartDate}
              onChange={(e) => setStartDate(e.target.value)}
            />
          </Box>
        </Grid>

        {/* Type of Review */}
        <Grid item xs={12}>
          <FormControl component="fieldset">
            <FormLabel
              component="legend"
              className="text-black"
              sx={{ fontSize: "1.15vw", paddingBottom: "1vw" }}
            >
              Type of Review *
            </FormLabel>
            <RadioGroup
              row
              value={reviewType}
              onChange={(e) => setReviewType(e.target.value)}
            >
              <FormControlLabel
                value="Probation Review"
                control={<Radio />}
                label="Probation Review"
                sx={{ marginRight: 2 }}
                componentsProps={{
                  typography: { sx: { fontSize: "1vw", fontWeight: "bold" } },
                }}
              />
              <FormControlLabel
                value="PIP"
                control={<Radio />}
                label="PIP"
                sx={{ marginRight: 2 }}
                componentsProps={{
                  typography: { sx: { fontSize: "1vw", fontWeight: "bold" } },
                }}
              />
              <FormControlLabel
                value="MIP"
                control={<Radio />}
                label="MIP"
                sx={{ marginRight: 2 }}
                componentsProps={{
                  typography: { sx: { fontSize: "1vw", fontWeight: "bold" } },
                }}
              />
              <FormControlLabel
                value="Annual Review"
                control={<Radio />}
                label="Annual Review"
                sx={{ marginRight: 2 }}
                componentsProps={{
                  typography: { sx: { fontSize: "1vw", fontWeight: "bold" } },
                }}
              />
            </RadioGroup>
          </FormControl>
        </Grid>
      </Grid>
    </Box>
  );
};

export default GeneralInformationForm;
