import * as React from "react"

import { cn } from "../utils/utils"

const Input = React.forwardRef(({ className, type, label, helperText, startAdornment = null, endAdornment = null, ...props }, ref) => {
  return (
    <div
      className={cn(
        "flex items-center bg-grey-100 h-auto w-full rounded-[10px] border border-input text-sm ring-offset-background disabled:cursor-not-allowed disabled:opacity-50",
        className
      )}>
      {startAdornment && <>{startAdornment}</>}
      <input
        type={type}
        // className={cn("flex-1 px-3 bg-grey-100 placeholder:text-muted-foreground focus:outline-none disabled:opacity-50")}
        ref={ref}
        {...props}
      />
      {endAdornment && <>{endAdornment}</>}
    </div>
  );
})
Input.displayName = "Input"

export { Input }
