import "../components/Navbar";
import Navbar from "../components/Navbar";
import "../styling/MyCertificates.css";
import certificateTemplate from "../images/certificate_template.png";
import { useEffect, useState } from "react";
import { saveAs } from "file-saver";
import { useNavigate, useLocation } from "react-router-dom";
import { getHeaders } from "../components/utils/useAuth";
import SideProfile from "../components/SideProfile";
import axiosInstance from "../utils/axiosInstance";
import CloseIcon from "../images/CloseIcon.png";
import axios from "axios";
import AdminNavbar from "../components/admin/common/AdNavbar";
import AdminLeftSideBar from "../components/admin/common/AdLeftSideBar";

export default function MyCertificates() {
  // if token is expired, navigate to login
  useEffect(() => {
    const token = sessionStorage.getItem("token");
    if (!token) {
      navigate("/login");
    }
  }, []);

  const location = useLocation();

  const [isHovered, setHovered] = useState(false);
  const [ID, setID] = useState();
  const [CourseId, SetCourseId] = useState(location.state?.courseID);
  const [selectedCerti, setSelectedCerti] = useState();
  const [showModal, setShowModal] = useState(false);
  const [certiList, setCertiList] = useState([]);
  const [completedCourses, setCompletedCourses] = useState(0);

  const navigate = useNavigate();

  const handleMouseEnter = (ind) => {
    setID(ind);
    setHovered(true);
  };

  const handleMouseLeave = () => {
    setID();
    setHovered(false);
  };

  const handleDownload = (certicateImage) => {
    // Create an 'a' element
    const link = document.createElement("a");
    link.href = certicateImage; // URL of the image
    link.download = "certificate.png"; // Desired file name
    // link.target = "_blank"; // Opens in a new tab to avoid navigation

    // Append the 'a' element to the body (required for Firefox)
    document.body.appendChild(link);

    // Programmatically click the 'a' element to trigger the download
    link.click();

    // Remove the 'a' element after download
    document.body.removeChild(link);
  };

  const handleView = (certicateImage) => {
    setShowModal(true);
    setSelectedCerti(certicateImage);
  };

  const getAllCertificates = () => {
    axiosInstance
      .get(`/training/all_certificates`)
      .then((res) => {
        console.log(
          "this is the image certificate! ",
          res.data["all_certificates"]
        );
        setCertiList(res.data["all_certificates"]);
        let i = 0;
        while (i < res.data["all_certificates"].length) {
          if (
            res.data["all_certificates"][i] != null &&
            res.data["all_certificates"][i] != ""
          ) {
            setCompletedCourses(completedCourses + 1);
          }
          i++;
        }
      })
      .catch((err) => {
        console.log("Sorry, there is no certificate for you.");
      });
  };
  useEffect(() => {
    getAllCertificates();
    generate_certificate();
    // const headers = {
    //   Authorization: `Bearer ${sessionStorage.getItem("token")}`,
    // };
    // axiosInstance
    //   .get(`/training/all_certificates`)
    //   .then((res) => {
    //     console.log(
    //       "this is the image certificate! ",
    //       res.data["all_certificates"]
    //     );
    //     setCertiList(res.data["all_certificates"]);
    //     let i = 0;
    //     while (i < res.data["all_certificates"].length) {
    //       if (
    //         res.data["all_certificates"][i] != null &&
    //         res.data["all_certificates"][i] != ""
    //       ) {
    //         setCompletedCourses(completedCourses + 1);
    //       }
    //       i++;
    //     }
    //   })
    //   .catch((err) => {
    //     console.log("Sorry, there is no certificate for you.");
    //   });
  }, []);

  const generate_certificate = () => {
    const url = `/training/generate_certificate/?course_id=${CourseId}`;

    // const url = `/training/generate_certificate/?course_id=${location.state.courseID}`;

    console.log("=====================");
    console.log(url);
    console.log("=====================");

    axiosInstance
      .get(url)
      // axiosInstance.get(`/training/all_certificates`, {headers})

      .then((res) => {
        console.log("this is the image certificate! ", res.data);
        // setCertImg(res.data["certificate_url"]);
        // const arr1 = res.data["certificate_url"].split("/");
        // const name = arr1[arr1.length - 2];
        // const arr2 = name.split("_");
        // setName(arr2[0] + "\t" + arr2[1]);
        getAllCertificates();
      })
      .catch((err) => {
        console.log("Sorry, there is no certificate for you.");
      });
  };

  return (
    <div className="my-cert-bg">
      <AdminLeftSideBar />
      {showModal ? (
        <div className="preview">
          <img src={selectedCerti} />
          <img
            onClick={() => setShowModal(false)}
            src={CloseIcon}
            alt="close-icon"
          />
        </div>
      ) : null}
      <div
        style={{ filter: showModal ? "blur(50px)" : "", position: "relative" }}
      >
        {/* <Navbar /> */}
        <AdminNavbar />
        <div className="certificates-bg">
          <div className="header-section">
            <h1>My Certificates</h1>
            <p>
              Take a look at all the courses you have completed successfully
            </p>
          </div>

          <hr />

          <div className="menu-section">
            <div>
              <div className="certificates-container">
                {certiList.map((certi, ind) => {
                  if (certi) {
                    return (
                      <div
                        className={`certificate ${
                          ind === ID ? "certificate-hover" : null
                        }`}
                        onMouseEnter={() => handleMouseEnter(ind)}
                        onMouseLeave={() => handleMouseLeave()}
                      >
                        {ind === ID ? (
                          <>
                            <div className="certificate-preview">
                              <button onClick={() => handleView(certi)}>
                                <span>View</span>
                              </button>
                              <button onClick={() => handleDownload(certi)}>
                                <span>Download</span>
                              </button>
                            </div>
                          </>
                        ) : (
                          <>
                            <img src={certi} alt="certificate-img"></img>
                          </>
                        )}
                      </div>
                    );
                  }
                })}
              </div>
            </div>
            {/* <SideProfile completedCourses={completedCourses} /> */}
          </div>
        </div>
      </div>
    </div>
  );
}
