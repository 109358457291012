import Logo from "../../../images/CPD_Logo.png";
import "../../../styling/admin/AdLeftSideBar.css";
import AdminBtnIcon from "../../../images/admin/icon-admin 1.svg";
import HomeBtnIcon from "../../../images/admin/mage_dashboard.svg";
import UserBtnIcon from "../../../images/admin/user-group-management 1.svg";
import CourseBtnIcon from "../../../images/admin/course-management-5 1.svg";
import MyCourseBtnIcon from "../../../images/admin/keep-learning 1.svg";
import ReportsBtnIcon from "../../../images/admin/reports_btn_icon.png";
import CourseCertBtn from "../../../images/admin/certificate-15 1.svg";
import OrgTreeBtnIcon from "../../../images/admin/orgtree.png";
import ApprslBtnIcon from "../../../images/admin/performance-appraisal-results-1 1.svg";
import { useNavigate } from "react-router-dom";

// Left side bar for admin side UI
export default function AdminLeftSideBar() {
  const navigate = useNavigate();
  const userRole = sessionStorage.getItem("role");
  const userPermission = sessionStorage.getItem("permissions"); // either "normal", "super", or "hyper"

  console.log("role value in admin left side bar", userRole)
  console.log("special permission value in admin left side bar",userPermission)

  const buttonData = [
    {
      id: 1,
      label: "Home",
      imgsrc: HomeBtnIcon,
      submithandler: () => navigate("/dashboard"),
      allowedCombinations: [
        { role: "admin" },
        { role: "user" },
        { role: "editor" }, // Open to all roles and permissions
      ],
    },
    {
      id: 2,
      label: "Admin",
      imgsrc: AdminBtnIcon,
      submithandler: () => navigate("/admin-dashboard"),
      allowedCombinations: [
        { role: "admin", permission: "normal" },
        { role: "admin", permission: "super" },
        { role: "admin", permission: "hyper" },
      ],
    },
    {
      id: 3,
      label: "Employee Management",
      imgsrc: UserBtnIcon,
      submithandler: () => navigate("/user-management"),
      allowedCombinations: [
        { role: "admin", permission: "normal" },
        { role: "admin", permission: "super" },
        { role: "admin", permission: "hyper" },
      ],
    },
    // {
    //   id: 4,
    //   label: "Org Tree",
    //   imgsrc: OrgTreeBtnIcon,
    //   submithandler: () => navigate("/organisation-tree"),
    //   allowedCombinations: [
    //     { role: "admin" },
    //     { role: "editor" },
    //     { role: "user" }, // Open to all roles and permissions
    //   ],
    // },
    {
      id: 5,
      label: "My Courses",
      imgsrc: MyCourseBtnIcon,
      submithandler: () => navigate("/my-courses"),
      allowedCombinations: [
        { role: "admin" },
        { role: "editor" },
        { role: "user" }, // Open to all roles and permissions
      ],
    },
    {
      id: 6,
      label: "Course Management",
      imgsrc: CourseBtnIcon,
      submithandler: () => navigate("/course-management"),
      allowedCombinations: [
        { role: "admin", permission: "normal" },
        { role: "admin", permission: "super" },
        { role: "admin", permission: "hyper" },
        { role: "editor", permission: "normal" },
        { role: "editor", permission: "super" },
        { role: "editor", permission: "hyper" },
      ],
    },
    {
      id: 7,
      label: "Course Reports",
      imgsrc: ReportsBtnIcon,
      submithandler: () => navigate("/course-report"),
      allowedCombinations: [
        { role: "admin" },
        { role: "user", permission: "super" },
        { role: "user", permission: "hyper" },
        { role: "editor", permission: "super" },
        { role: "editor", permission: "hyper" },
      ],
    },
    {
      id: 8,
      label: "Certificate",
      imgsrc: CourseCertBtn,
      submithandler: () => navigate("/my-certificates"),
      allowedCombinations: [
        { role: "admin" },
        { role: "editor" },
        { role: "user" }, // Open to all roles and permissions
      ],
    },
    {
      id: 9,
      label: "Appraisal",
      imgsrc: ApprslBtnIcon,
      submithandler: () => navigate("/appraisal"),
      allowedCombinations: [
        { role: "admin", permission: "super" },
        { role: "admin", permission: "hyper" },
        { role: "user", permission: "super" },
        { role: "user", permission: "hyper" },
        { role: "editor", permission: "super" },
        { role: "editor", permission: "hyper" },
        { role: "user", permission: "normal" },
      ],
    },
  ];

  // Function to check if the button should be displayed
  const hasPermission = (button) => {
    return button.allowedCombinations.some(
      (combination) =>
        combination.role === userRole &&
        (!combination.permission || combination.permission === userPermission)
    );
  };

  return (
    <div className="ad-left-sidebar-bg">
      <div className="ls-logo-container">
        <img
          src={Logo}
          alt="Logo"
          className="ls-cpd-logo"
          onClick={() => navigate("/admin-dashboard")}
        />
      </div>
      {buttonData.map(
        (button) =>
          hasPermission(button) && (
            <div
              key={button.id}
              className="ls-button cursor-pointer"
              onClick={button.submithandler}
            >
              <img src={button.imgsrc} alt={button.label} />
              <span>{button.label}</span>
            </div>
          )
      )}
    </div>
  );
}
