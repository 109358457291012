import Editor from "react-simple-wysiwyg";
import "../../../../styling/admin/AdAddCourseContent.css";
import { useEffect, useState } from "react";
import { useGlobalState } from "../../../GlobalStateContext";
import axiosInstance from "../../../../utils/axiosInstance";
import { getHeaders } from "../../../utils/useAuth";
import AlertMessage from "../../../../utils/alertMessage";
import ConfirmationPopup from "../../../../utils/confirmationPopup";

export default function AddLessonContent(props) {
  var { courseId, moduleId, lessonId, fetchLessons } = props;
  const [role, setRole] = useState("");

  useEffect(() => {
    const userRole = sessionStorage.getItem("role");
    setRole(userRole);
  }, []);

  const initialState = {
    module_id: moduleId,
    title: "",
    description: "",
  };

  // state variables
  const [tags, setTags] = useState([]);
  const [tagInput, setTagInput] = useState("");
  const [contentState, setContentState] = useState(initialState);
  const [existingLesson, setExistingLesson] = useState(false);
  const [alertState, setAlertState] = useState({
    open: false,
    message: "",
    variant: "success",
  });
  const [confirmState, setConfirmState] = useState({
    open: false,
    message: "",
    onConfirm: () => {},
  });

  useEffect(() => {
    if (lessonId !== null) {
      setExistingLesson(true);
    } else {
      setExistingLesson(false);
      setContentState(initialState);
    }
  }, [lessonId]);

  useEffect(() => {
    if (existingLesson && lessonId != null) {
      axiosInstance
        .get(`/lessons/` + lessonId)
        .then((res) => {
          setContentState(res.data);
        })
        .catch((err) => {
          console.error("Error fetching lesson data:", err);
          setAlertState({
            open: true,
            message: "Error fetching lesson data",
            variant: "error",
          });
        });
    }
  }, [existingLesson, lessonId]);

  const handleConfirmSave = async () => {
    const newCourseContentPayload = {
      title: contentState.title,
      description: contentState.description,
      module_id: moduleId,
    };

    try {
      if (!existingLesson) {
        const res = await axiosInstance.post(
          `/lessons/`,
          newCourseContentPayload
        );
        setContentState(res.data);
        setExistingLesson(true);
        setAlertState({
          open: true,
          message: "Lesson created successfully!",
          variant: "success",
        });
        // Reset state to initial values after successful save
        setContentState(initialState);
        setExistingLesson(false); // Reset existingLesson to false
      } else {
        await axiosInstance.put(
          `/lessons/` + lessonId,
          newCourseContentPayload
        );
        setAlertState({
          open: true,
          message: "Lesson updated successfully!",
          variant: "success",
        });
      }
      fetchLessons(moduleId);
    } catch (err) {
      console.error("Error saving lesson:", err);
      setAlertState({
        open: true,
        message: "Error saving lesson",
        variant: "error",
      });
    }
  };

  const saveContent = (e) => {
    e.preventDefault();

    const newCourseContentPayload = {
      title: contentState.title,
      description: contentState.description,
      module_id: moduleId,
    };

    const confirmationMessage = existingLesson
      ? `Update the ${newCourseContentPayload.title} lesson?`
      : `Save the ${newCourseContentPayload.title} lesson?`;

    setConfirmState({
      open: true,
      message: confirmationMessage,
      onConfirm: handleConfirmSave,
    });
  };

  const handleConfirmDelete = async () => {
    try {
      await axiosInstance.delete(`/lessons/` + lessonId);
      setContentState(initialState); // Resets contentState to initial state
      setExistingLesson(false); // Resets existingLesson to false
      lessonId = null; // Ensures lessonId is null after deletion
      fetchLessons(moduleId);
      setAlertState({
        open: true,
        message: "Lesson deleted successfully!",
        variant: "success",
      });
    } catch (error) {
      console.error("Error deleting lesson:", error);
      setAlertState({
        open: true,
        message: "Error deleting lesson",
        variant: "error",
      });
    }
  };

  const deleteModuleHandler = (e) => {
    e.preventDefault();

    const confirmationMessage = "Delete the lesson?";

    setConfirmState({
      open: true,
      message: confirmationMessage,
      onConfirm: handleConfirmDelete,
    });
  };

  return (
    <div className="course-content-bg">
      <div className="sections-container">
        <div className="top-section">
          <div className="title-section">
            <h5>Title</h5>
            <Editor
              value={contentState.title}
              onChange={(e) =>
                setContentState((prevState) => ({
                  ...prevState,
                  title: e.target.value,
                }))
              }
              disabled={role === "user" || role === "manager"}
            />
          </div>
        </div>
        <div className="lower-section">
          <h5>Content</h5>
          <Editor
            value={contentState.description}
            onChange={(e) =>
              setContentState((prevState) => ({
                ...prevState,
                description: e.target.value,
              }))
            }
            disabled={role === "user" || role === "manager"}
          />
        </div>
      </div>
      {!(role === "user" || role === "manager") && (
        <div className="lesson-content-save-btn">
          <button onClick={saveContent}>Save</button>
          <button onClick={deleteModuleHandler}>Delete</button>
        </div>
      )}
      {confirmState.open && (
        <ConfirmationPopup
          message={confirmState.message}
          onConfirm={() => {
            confirmState.onConfirm();
            setConfirmState({ ...confirmState, open: false });
          }}
          onCancel={() => setConfirmState({ ...confirmState, open: false })}
          onClose={() => setConfirmState({ ...confirmState, open: false })}
        />
      )}

      {/* Single AlertMessage Component */}
      {alertState.open && (
        <AlertMessage
          message={alertState.message}
          variant={alertState.variant}
          onClose={() => setAlertState({ ...alertState, open: false })}
        />
      )}
    </div>
  );
}
