import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import "../../styling/Login.css";
import UserIcon from "../../images/user.png";
import PadLockIcon from "../../images/padlock.png";
import CPD_Logo from "../../images/CPD_Logo.png";
import axiosInstance from "../../utils/axiosInstance";
import { setItem, removeItem } from "../../utils/storage";
import AlertMessage from "../../utils/alertMessage";
import { useForm } from 'react-hook-form'
import { zodResolver } from "@hookform/resolvers/zod"
import { z } from "zod"
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormMessage,
} from "../../components/ui/form"
import { Input } from "../../components/ui/input"

const formSchema = z
  .object({
    username: z.string({ required_error: "Username is required" }).email({ message: "Enter valid email" }),
    password: z.string({ required_error: "Password is required" }),
  })

export default function LoginComponent() {

  const form = useForm({
    resolver: zodResolver(formSchema),
  })

  const navigate = useNavigate();
  const [alertState, setAlertState] = useState({
    open: false,
    message: "",
    variant: "success",
  });

  useEffect(() => {
    removeItem("token");
    removeItem("refreshToken");
  }, []);

  const submitHandler = async (data, e) => {
    e.preventDefault();
    const {
      username,
      password,
    } = data;

    const payload = `grant_type=&username=${username}&password=${password}&scope=&client_id=&client_secret=`;

    try {
      const res = await axiosInstance.post(`/auth/user_token`, payload);
      const accessToken = res.data.access_token;
      const refreshToken = res.data.refresh_token;
      setItem("token", accessToken);
      setItem("refreshToken", refreshToken);
      setItem("username", username);

      const userRes = await axiosInstance.get(`/users/user_details`);
      setItem("role", userRes.data["role"]);
      setItem("permissions", userRes.data["special_permissions"]);
      setItem("loggedInUserId", userRes.data["id"]);

      setAlertState({
        open: true,
        message: "Login successful!",
        variant: "success",
      });

      setTimeout(() => {
        setAlertState({
          ...alertState,
          open: false,
        });

        if (userRes.data.role === "user" || userRes.data.role === "manager") {
          navigate("/dashboard");
        } else {
          navigate("/dashboard", {
            state: { first_name: userRes.data.first_name },
          });
        }
      }, 1000); // Hide success message after 2 seconds
    } catch (err) {
      setAlertState({
        open: true,
        message: "Login failed! Please enter valid credentials.",
        variant: "error",
      });
    }
  };

  return (
    <div className="login-bg flex-col">
      <div>
        <img className="cpd-logo" src={CPD_Logo} alt="CPD Logo" />
      </div>
      <div className="login-form">
        <h2 className="login-header">LOGIN</h2>
        <p className="login-desc mb-4">Please enter login ID and password</p>
        <Form {...form}>
          <form onSubmit={form.handleSubmit(submitHandler)} className="space-y-0 text-center min-w-[250px]">
            <FormField
              control={form.control}
              name="username"
              render={({ field }) => (
                <div className="mb-2">
                  <FormItem id="email-bar" className="space-y-0 w-fill">
                    <FormControl>
                      <>
                        <Input
                          placeholder="Username" {...field} ></Input>
                        <img src={UserIcon} alt="User Icon" />
                      </>
                    </FormControl>
                  </FormItem>
                  <FormMessage className="text-left" />
                </div>

              )}
            />
            <FormField
              control={form.control}
              name="password"
              render={({ field }) => (
                <div className="!mb-2">
                  <FormItem id="password-bar" className="space-y-0 w-fill">
                    <FormControl>
                      <>
                        <Input
                          placeholder="Password" type="password" {...field} />
                        <img src={PadLockIcon} alt="Padlock Icon" />
                      </>
                    </FormControl>
                  </FormItem>
                  <FormMessage className="text-left" />
                </div>
              )}
            />
            <div className="login-fgt-pwd !mb-4">
              Reset Password?
              <Link to="/reset-password">
                <i>Reset Password</i>
              </Link>
            </div>
            <button className="login-btn" type="submit">Login</button>
          </form>
        </Form>
      </div>


      {/* <div id="email-bar">
          <input
            type="text"
            placeholder="Enter username"
            // value={username}
            // onChange={(e) => setUsername(e.target.value)}
            {...register("userName")}
          />
          <img src={UserIcon} alt="User Icon" />
        </div>
        {errors.userName && <p className="error">{errors.userName.message}</p>} */}

      {/* <div id="password-bar">
          <input
            type="password"
            placeholder="Enter password"
            // value={password}
            // onChange={(e) => setPassword(e.target.value)}
            {...register("password")}
          />
          <img src={PadLockIcon} alt="Padlock Icon" />
        </div> */}


      {/* <p className="register-opt">
          Not a member?
          <Link to="/register">
            <i> Register</i>
          </Link>
        </p> */}

      {/* Single AlertMessage Component */}
      {alertState.open && (
        <AlertMessage
          message={alertState.message}
          variant={alertState.variant}
          onClose={() => setAlertState({ ...alertState, open: false })}
        />
      )}
    </div>
  );
}
