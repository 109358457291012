import * as React from "react";
import "../../../styling/admin/AdCourseStructure.css";
import AddBtnIcon from "../../../images/admin/add_course_btn_icon.png";
import AddModule from "./module/AdAddModule";
import AddLessonContent from "./lessons/AdAddLessonContent";
import AddCourseQuestions from "./questions/AdAddCourseQuestions";
import AdQuestionsPreview from "./questions/AdQuestionsPreview";
import { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import axiosInstance from "../../../utils/axiosInstance";
import { LessonData } from "../../../LessonData";
import { getHeaders } from "../../utils/useAuth";
import Collapse from "@mui/material/Collapse";

// const headers = {
//   Authorization: `Bearer ${sessionStorage.getItem("token")}`,
// };

export default function CourseStructureTab(props) {
  const [value, setValue] = React.useState("1");
  var [modulesData, setModulesData] = useState([]);
  var [lessonsData, setLessonData] = useState([]);
  const [courseId, setCourseId] = useState(props.courseId);
  const [curModId, setCurModId] = useState(null);
  const [curLesId, setCurLesId] = useState(null);
  const [showModCreation, setShowModCreation] = useState(false);
  const [showLesCreation, setShowLesCreation] = useState(false);
  const [showQuestionsTab, setShowQuestionsTab] = useState(false);
  const [editedQuestion, setEditedQuestion] = useState(null);
  const [role, setRole] = useState("");

  useEffect(() => {
    const userRole = sessionStorage.getItem("role");
    setRole(userRole);
  }, []);

  const handleEditQuestion = (question) => {
    setValue("2"); // Switch to the Questions tab
    setShowQuestionsTab(true); // Set the visibility of the Questions tab to true
    // setShowModCreation(false);
    // setShowLesCreation(true);
    setEditedQuestion(question);
  };

  // Function to fetch modules from backend and update state
  const fetchModules = () => {
    var getAllModulesUrl = `/modules/` + courseId + `/all`;
    axiosInstance
      .get(getAllModulesUrl)
      .then((res) => {
        const moduleData = res.data;
        moduleData[0].course_banner =
          "https://cpdpeopeportal.s3.ap-south-1.amazonaws.com/course_banners/1.png";
        setModulesData(moduleData);
        console.log(moduleData);
      })
      .catch((error) => {
        console.log("error while fetching module data :", error);
      });
  };

  useEffect(() => {
    fetchModules();
  }, [courseId]);

  const fetchLessons = (moduleID) => {
    var getAllLessonsUrl = `/lessons/` + moduleID + `/all`;
    axiosInstance
      .get(getAllLessonsUrl)
      .then((res) => {
        setLessonData(res.data);
      })
      .catch((error) => {
        console.log("error while fetching lesson data :", error);
      });
  };

  const onModuleClick = (moduleID) => {
    console.log("current module clicked is", moduleID);
    setLessonData([]);
    setCurModId((prevId) => (prevId === moduleID ? null : moduleID));

    // fetchModules();
    // If moduleID is not null, fetch lessons related to that module
    if (moduleID !== null) {
      fetchLessons(moduleID);
    } else {
      // If moduleID is null, reset lessonsData to empty array
      setLessonData([]);
    }

    setShowModCreation(true);
    setShowLesCreation(false);
  };

  const lessonClick = (moduleID, lessonID) => {
    console.log("================");
    console.log(
      "current lesson id that is clicked recieved on parent side:",
      lessonID
    );
    console.log("================");
    setCurModId(moduleID);
    setCurLesId(lessonID);
    setShowLesCreation(true);
    setShowModCreation(false);
    // Reset to the "Content" tab
    setValue("1");
  };

  const navigateToQuestionsTab = () => {
    setValue("3"); // Navigate to Questions Preview tab
  };

  return (
    <div className="course-struct-bg">
      <div className="course-struct-left-container">
        <div className="header">
          <span>Structure</span>

          {!(role === "user" || role === "manager") && (
            <div className="add-clik" onClick={() => onModuleClick(null)}>
              <img src={AddBtnIcon} alt="add-icon" />
              <span>Add</span>
            </div>
          )}
        </div>

        {modulesData.map((module) => (
          <div key={module.id}>
            <div
              className="one-module"
              onClick={() => onModuleClick(module.id)}
            >
              {/* <img src={module.course_banner} alt="module-thumbnail" /> */}
              <img
                src="https://cpdpeopeportal.s3.ap-south-1.amazonaws.com/course_banners/1.png"
                alt="module-thumbnail"
              />
              <div className="module-vertical-flex">
                <span dangerouslySetInnerHTML={{ __html: module.title }}></span>
                <span
                  dangerouslySetInnerHTML={{ __html: module.description }}
                ></span>
              </div>
            </div>
            <Collapse in={curModId === module.id}>
              <div>
                {lessonsData.map((lesson) => (
                  <div
                    key={lesson.id}
                    className="one-lesson"
                    onClick={() => lessonClick(module.id, lesson.id)}
                  >
                    <span
                      dangerouslySetInnerHTML={{ __html: lesson.title }}
                    ></span>
                    <span
                      dangerouslySetInnerHTML={{ __html: lesson.title }}
                    ></span>
                  </div>
                ))}
                {!(role === "user" || role === "manager") && (
                  <div
                    className="add-lesson"
                    onClick={() => lessonClick(module.id, null)}
                  >
                    <img src={AddBtnIcon} alt="add-icon" />
                    <span>Add Lesson</span>
                  </div>
                )}
              </div>
            </Collapse>
          </div>
        ))}
      </div>
      <div className="course-struct-right-container">
        {showModCreation && (
          <AddModule
            courseId={props.courseId}
            moduleId={curModId}
            fetchModules={fetchModules}
          />
        )}
        {showLesCreation && (
          <Box sx={{ width: "110%", typography: "body1" }}>
            <TabContext value={value}>
              <Box
                sx={{
                  borderBottom: 1,
                  borderColor: "divider",
                  marginTop: "0.2vw",
                }}
              >
                <TabList
                  onChange={(e, newValue) => {
                    setValue(newValue);
                    setEditedQuestion(null); // Reset editedQuestion to null when tab changes
                  }}
                  aria-label="lab API tabs example"
                >
                  <Tab label="Content" value="1" />
                  {!(role === "user" || role === "manager") && (
                    <Tab label="Question" value="2" style={{display: curLesId === null ?  "none" : "block"}} />
                  )}
                  <Tab label="Questions Preview" value="3" style={{display: curLesId === null ?  "none" : "block"}} />
                </TabList>
              </Box>
              <TabPanel value="1">
                <AddLessonContent
                  courseId={props.courseId}
                  moduleId={curModId}
                  lessonId={curLesId}
                  fetchLessons={fetchLessons}
                />
              </TabPanel>
              {!(role === "user" || role === "manager") && (
                <TabPanel value="2" style={{display: curLesId === null ?  "none" : "block"}}>
                  <AddCourseQuestions
                    courseId={props.courseId}
                    moduleId={curModId}
                    lessonId={curLesId}
                    editedQuestion={editedQuestion}
                    navigateToQuestionsTab={navigateToQuestionsTab} // Pass the prop here
                  />
                </TabPanel>
              )}
              <TabPanel value="3" style={{display: curLesId === null ?  "none" : "block"}}>
                <AdQuestionsPreview
                  courseId={props.courseId}
                  moduleId={curModId}
                  lessonId={curLesId}
                  onEditQuestion={handleEditQuestion}
                />
              </TabPanel>
            </TabContext>
          </Box>
        )}
      </div>
    </div>
  );
}
