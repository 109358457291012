import { useEffect } from "react";
import React from "react";
import Navbar from "../components/Navbar";
import "../styling/TestResults.css";
import { useNavigate, useLocation } from "react-router-dom";
import Close from "../images/CloseIcon.png";

export default function ModuleReults() {
  // if token is expired, navigate to login
  useEffect(() => {
    const token = sessionStorage.getItem("token");
    if (!token) {
      navigate("/login");
    }
  }, []);

  const navigate = useNavigate();
  const location = useLocation();

  console.log("module result: ", location.state);

  const {
    module_percent,
    module_verdict,
    course_percent,
    course_verdict,
    courseDetails,
  } = location.state;
  //   const checkCourseResults = () =>
  //   {

  //     if (verdict=="fail")
  //       navigate("/my-courses")
  //     else
  //       navigate("/view-certificate",{state:1})

  //   };
  console.log(courseDetails.moduleID + "& " + courseDetails.totalModules);
  return (
    <>
      <Navbar />
      <div className="result-bg">
        <div className="result-box">
          <img
            src={Close}
            alt="close-test-results"
            onClick={() => navigate(-2)}
          />

          <h1
            dangerouslySetInnerHTML={{ __html: courseDetails.moduleTitle }}
          ></h1>
          {module_verdict == "fail" ? (
            <h2 style={{ color: "red" }}>Try Again!</h2>
          ) : (
            <h2 style={{ color: "#47A30F" }}>Congratulations!</h2>
          )}
          <h3>You have scored {module_percent}%</h3>
          {module_verdict != "fail" ? (
            <button
              className="module-results-button"
              onClick={
                course_verdict
                  ? () =>
                      navigate(`/testresult`, {
                        state: {
                          course_percent,
                          course_verdict,
                          courseID: courseDetails.courseID,
                        },
                      })
                  : () => navigate(-2)
              }
            >
              {course_verdict
                ? "View Course Results"
                : "Proceed to Next Module"}
            </button>
          ) : (
            <div style={{ display: "flex", gap: "1vw" }}>
              <button
                onClick={() => navigate(-1)}
                className="module-results-button"
              >
                Attempt Now
              </button>

              {/* <Link to={`/moduleLists/${courseDetails.courseID}`}> */}
              <button
                onClick={() => navigate(-2)}
                className="module-results-button"
              >
                Later
              </button>
              {/* </Link> */}
            </div>
          )}
        </div>
      </div>
    </>
  );
}
