import "../styling/admin/AdCourseManagement.css";
import CoursesManagementTabs from "../components/admin/course-management/AdCourseManagementTabs";
import AdminLeftSideBar from "../components/admin/common/AdLeftSideBar";
import AdminNavbar from "../components/admin/common/AdNavbar";
import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";

// course management page
export default function CoursesManagement() {
  const navigate = useNavigate();

  // if token is expired, navigate to login
  useEffect(() => {
    const token = sessionStorage.getItem("token");
    if (!token) {
      navigate("/login");
    }
  }, []);

  return (
    <div className="courseManagement-bg">
      <AdminLeftSideBar />
      <div className="management-section">
        <AdminNavbar />
        <h2>Course Management</h2>
        <CoursesManagementTabs />
      </div>
    </div>
  );
}
