import React, { useState, useRef, useEffect } from "react";
import Dropdown from "@mui/joy/Dropdown";
import Menu from "@mui/joy/Menu";
import MenuButton from "@mui/joy/MenuButton";
import MenuItem from "@mui/joy/MenuItem";
import Editor from "react-simple-wysiwyg";
import CameraIcon from "../images/CameraIcon.png";
import "../styling/admin/AdCourseDetails.css";
import axiosInstance from "../utils/axiosInstance";
import { useNavigate } from "react-router-dom";
import { getHeaders } from "../components/utils/useAuth";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import AlertMessage from "../utils/alertMessage";
import ConfirmationPopup from "../utils/confirmationPopup";

// const headers = {
//   Authorization: `Bearer ${sessionStorage.getItem("token")}`,
// };

const initialState = {
  id: 0,
  title: "",
  description: "",
  pass_pct: "",
  course_banner: "",
  certificate_template: "",
  selectedUser: "",
  author: 0,
  rating: null,
  saveBtn: "",
  status: "Not Approved",
};

const CourseDetailsTab = (props) => {
  const navigate = useNavigate();
  const [alertState, setAlertState] = useState({
    open: false,
    message: "",
    variant: "success",
  });

  const [confirmState, setConfirmState] = useState({
    open: false,
    message: "",
    onConfirm: () => {},
  });

  // if token is expired, navigate to login
  useEffect(() => {
    const token = sessionStorage.getItem("token");
    if (!token) {
      navigate("/login");
    }
  }, []);

  // const Alert = React.forwardRef(function Alert(props, ref) {
  //   return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
  // });

  // Check auth token on component mount

  // const location = useLocation();
  // const [userID, setUserID] = useState(location.state?.userID);
  const [courseId, setExistingCourseId] = useState(props.courseId);
  const [existingCourse, setExistingCourse] = useState(false);
  const [detailsState, setDetailsState] = useState(initialState);
  const [errorState, setErrorState] = useState(initialState);
  const hiddenFileInput1 = useRef(null);
  const hiddenFileInput2 = useRef(null);
  const [role, setRole] = useState("");
  const [isFormDirty, setIsFormDirty] = useState(false);
  const [author, setAuthor] = useState(10003);
  // const [snackbarOpen, setSnackbarOpen] = useState(false);
  // const [snackbarMessage, setSnackbarMessage] = useState("");
  // const [snackbarSeverity, setSnackbarSeverity] = useState("success");

  // const [headers, setHeaders] = useState(0);

  // const getHeaders = () => ({
  //   Authorization: `Bearer ${sessionStorage.getItem("token")}`,
  // });

  // setting author
  useEffect(() => {
    axiosInstance
      .get(`/users/user_details`)
      .then((res) => {
        console.log("author", res.data["id"]);
        setAuthor(res.data["id"]);
      })
      .catch((err) => {
        console.error("Error fetching user data:", err);
      });
  }, []);

  useEffect(() => {
    const userRole = sessionStorage.getItem("role");

    setRole(userRole);
  }, []);

  useEffect(() => {
    if (courseId !== null) {
      setExistingCourse(true);
    }
  }, [courseId]);

  // useEffect(() => {
  //   if (existingCourse) {
  //     fetchCourseDetails();
  //   }
  // }, [existingCourse, courseId]);

  useEffect(() => {
    if (courseId !== null && existingCourse) {
      fetchCourseDetails();
    }
  }, [courseId, existingCourse]);

  useEffect(() => {
    const handleBeforeUnload = (event) => {
      if (isFormDirty) {
        event.preventDefault();
        event.returnValue =
          "You have unsaved changes. Are you sure you want to leave?";
      }
    };

    window.addEventListener("beforeunload", handleBeforeUnload);
    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, [isFormDirty]);

  const fetchCourseDetails = async () => {
    try {
      var getCourseUrl = `/courses/` + courseId;
      const res = await axiosInstance.get(getCourseUrl);
      const sampleCourseData = res.data;
      sampleCourseData.pass_pct = sampleCourseData.pass_pct.toString();
      setDetailsState(sampleCourseData);
    } catch (error) {
      console.error("Error fetching course data:", error);
    }
  };

  // Function to handle input change and set form as dirty
  const handleInputChange = (field, value) => {
    if (field === "pass_pct") {
      // Ensure pass_pct is a number between 1 and 100
      const numericValue = parseInt(value, 10);
      if (numericValue < 1 || numericValue > 100 || isNaN(numericValue)) {
        setErrorState((prevState) => ({
          ...prevState,
          pass_pct: "Passing criteria must be a number between 1 and 100",
        }));
      } else {
        setErrorState((prevState) => ({
          ...prevState,
          pass_pct: "",
        }));
        setDetailsState((prevState) => ({
          ...prevState,
          pass_pct: value,
        }));
      }
    } else if (field === "title") {
      // Sanitize title to strip HTML tags
      const sanitizedValue = document.createElement("textarea");
      sanitizedValue.innerHTML = value;
      const cleanTitle = sanitizedValue.innerText;

      setErrorState((prevState) => ({
        ...prevState,
        [field]: "",
      }));

      setDetailsState((prevState) => ({
        ...prevState,
        [field]: cleanTitle,
      }));
    } else {
      setErrorState((prevState) => ({
        ...prevState,
        [field]: "",
      }));

      setDetailsState((prevState) => ({
        ...prevState,
        [field]: value,
      }));
    }
    setIsFormDirty(true); // Mark form as dirty on input change
  };

  const handleThumbnailChange = (event) => {
    const fileUploaded = event.target.files[0];
    const reader = new FileReader();
    reader.onload = function () {
      setDetailsState((prevState) => ({
        ...prevState,
        course_banner: reader.result,
      }));
      setErrorState((prevState) => ({
        ...prevState,
        course_banner: "",
      }));
      setIsFormDirty(true); // Mark form as dirty on file change
    };
    reader.readAsDataURL(fileUploaded);
  };

  const handleCertificateChange = (event) => {
    const fileUploaded = event.target.files[0];
    const reader = new FileReader();
    reader.onload = function () {
      setDetailsState((prevState) => ({
        ...prevState,
        certificate_template: reader.result,
      }));
      setErrorState((prevState) => ({
        ...prevState,
        certificate_template: "",
      }));
      setIsFormDirty(true); // Mark form as dirty on file change
    };
    reader.readAsDataURL(fileUploaded);
  };

  const saveCourseHandler = async (e) => {
    e.preventDefault();
    setErrorState(initialState);

    try {
      validateForm();
      await updateCourseState();
      const newCoursePayload = createCoursePayload();
      const confirmationMessage = existingCourse
        ? "Update the " + newCoursePayload.title + " course?"
        : "Save the " + newCoursePayload.title + " course?";
      const handleConfirm = async () => {
        if (!existingCourse) {
          await createCourse(newCoursePayload);
          // setSnackbarMessage("Course created successfully!");
        } else {
          await updateCourse(newCoursePayload);
          // setSnackbarMessage("Course updated successfully!");
        }
        // setSnackbarSeverity("success");
        setIsFormDirty(false);
      };
      // const confirmSave = window.confirm(confirmationMessage);

      // if (confirmSave) {
      //   if (!existingCourse) {
      //     await createCourse(newCoursePayload);
      //     // setSnackbarMessage("Course created successfully!");
      //   } else {
      //     await updateCourse(newCoursePayload);
      //     // setSnackbarMessage("Course updated successfully!");
      //   }
      //   // setSnackbarSeverity("success");
      //   setIsFormDirty(false);
      // }
      setConfirmState({
        open: true,
        message: confirmationMessage,
        onConfirm: handleConfirm,
      });
    } catch (error) {
      console.error("Error saving course:", error);
      // setSnackbarMessage("Error saving course. Please try again.");
      // setSnackbarSeverity("error");
      setAlertState({
        open: true,
        message: "Error in course operation.",
        variant: "error",
      });
    } finally {
      // setSnackbarOpen(true);
    }
  };

  const validateForm = () => {
    const errors = {};
    if (!detailsState.title) errors.title = "Please add course title";
    if (!detailsState.description)
      errors.description = "Please add course description";
    if (!detailsState.pass_pct) {
      errors.pass_pct = "Please add passing criteria";
    } else if (
      isNaN(detailsState.pass_pct) ||
      detailsState.pass_pct < 1 ||
      detailsState.pass_pct > 100
    ) {
      errors.pass_pct = "Passing criteria must be a number between 1 and 100";
    }
    if (!detailsState.course_banner) {
      errors.course_banner = "Please upload course thumbnail";
    }
    if (!detailsState.certificate_template) {
      errors.certificate_template = "Please upload course certificate";
    }
    setErrorState((prevState) => ({ ...prevState, ...errors }));
    if (Object.keys(errors).length > 0) {
      throw new Error("Validation failed");
    }
  };

  const updateCourseState = async () => {
    await setDetailsState((prevState) => ({ ...prevState }));
  };

  const createCoursePayload = () => {
    return {
      title: detailsState.title,
      description: detailsState.description,
      pass_pct: detailsState.pass_pct,
      author: author,
      rating: "",
      course_banner: detailsState.course_banner,
      certificate_template: detailsState.certificate_template,
      status: existingCourse ? detailsState.status : "Not Approved",
      // status: detailsState.status,
    };
  };

  const createCourse = async (newCoursePayload) => {
    var postCourseUrl = `/courses/`;
    const res = await axiosInstance.post(postCourseUrl, newCoursePayload);
    if (res.status === 201) {
      const newCourseData = res.data;
      newCourseData.pass_pct = res.data.pass_pct.toString();
      setDetailsState(newCourseData);
      const newCourseId = res.data["id"];
      setExistingCourseId(newCourseId); // Update the courseId state
      setExistingCourse(true);
      console.log("Course created successfully!");
      setAlertState({
        open: true,
        message: "Course created successfully!",
        variant: "success",
      });
      setTimeout(() => {
        navigate("/course-management");
      }, 500);
    } else {
      console.log("Unexpected response from server:", res);
      setAlertState({
        open: true,
        message: "Error creating course!",
        variant: "error",
      });
    }
  };

  const updateCourse = async (newCoursePayload) => {
    const putCourseUrl = `/courses/` + courseId;
    const res = await axiosInstance.put(putCourseUrl, newCoursePayload);
    // navigate("/course-management");
    setAlertState({
      open: true,
      message: "Course updated successfully!",
      variant: "success",
    });
    console.log("Update course response:", res);
  };

  const handleUserSelection = (user) => {
    handleInputChange("selectedUser", user.Name);
    setIsFormDirty(true); // Mark form as dirty on user selection
  };

  const handleThumbnailUpload = () => {
    hiddenFileInput1.current.click();
  };

  const handleCertificateUpload = () => {
    hiddenFileInput2.current.click();
  };

  const deleteCourseHandler = async (e) => {
    e.preventDefault();
    const confirmationMessage = "Delete the course?";
    // const confirmDelete = window.confirm(confirmationMessage);
    const handleConfirm = async () => {
      const deleteCourseUrl = `/courses/` + courseId;
      try {
        const res = await axiosInstance.delete(deleteCourseUrl);
        console.log("Course deleted:", res.data);
        setDetailsState(initialState);
        setExistingCourseId(null);
        setExistingCourse(false);
        // setSnackbarMessage("Course deleted successfully!");
        // setSnackbarSeverity("success");
        setAlertState({
          open: true,
          message: "Course deleted successfully!",
          variant: "success",
        });
        setTimeout(() => {
          navigate("/course-management");
        }, 500);
      } catch (error) {
        console.error("Error deleting course:", error);
        // setSnackbarMessage("Error deleting course. Please try again.");
        // setSnackbarSeverity("error");
        setAlertState({
          open: true,
          message: "Course Deletion failed!",
          variant: "success",
        });
      } finally {
        // setSnackbarOpen(true);
      }
    };
    setConfirmState({
      open: true,
      message: confirmationMessage,
      onConfirm: handleConfirm,
    });
  };

  const cancelCourseHandler = () => {
    const confirmationMessage = existingCourse
      ? "your modified changes will be reset, want to continue?"
      : "your new changes will be reset, want to continue?";

    const confirmCancel = window.confirm(confirmationMessage);
    if (confirmCancel) {
      if (!existingCourse || !courseId) {
        setDetailsState(initialState);
      } else {
        fetchCourseDetails();
      }
    }
  };

  const publishCourseHandler = () => {
    setDetailsState(initialState);
  };

  return (
    <div className="course-create-bg">
      {/* Prompt to warn user about unsaved changes */}
      {/* <Prompt
        when={isFormDirty}
        message="You have unsaved changes. Are you sure you want to leave?"
      /> */}

      <div className="course-details-left-container">
        <h4>Course Details</h4>

        <div className="label-header">
          <h5>Course Title</h5>
          {errorState.title && (
            <span className="error-msg">{errorState.title}</span>
          )}
        </div>

        <div id="course-title-editor">
          <Editor
            value={detailsState.title}
            onChange={(e) => handleInputChange("title", e.target.value)}
            disabled={role === "user" || role === "manager"}
          />
        </div>

        <div className="label-header">
          <h5>Description</h5>
          {errorState.description && (
            <span className="error-msg">{errorState.description}</span>
          )}
        </div>
        <div id="course-desc-editor">
          <Editor
            value={detailsState.description}
            onChange={(e) => handleInputChange("description", e.target.value)}
            disabled={role === "user" || role === "manager"}
          />
        </div>

        <div className="flex-row">
          <h5>Passing Criteria</h5>
          <div id="course-percent-editor">
            <Editor
              value={detailsState.pass_pct}
              onChange={(e) => handleInputChange("pass_pct", e.target.value)}
              disabled={role === "user" || role === "manager"}
            />
          </div>
          {errorState.pass_pct && (
            <span className="error-msg">{errorState.pass_pct}</span>
          )}
        </div>
      </div>
      <div className="course-details-right-container">
        <h5>Add Thumbnail</h5>
        <div className="cd-image-uploader">
          <div>
            <img
              className={`${
                detailsState.course_banner ? "uploadedImg" : "icon"
              }`}
              src={
                detailsState.course_banner
                  ? detailsState.course_banner
                  : CameraIcon
              }
              alt="camera-icon"
            />
          </div>
          {!(role === "user" || role === "manager") && (
            <button onClick={handleThumbnailUpload}>
              Upload Course Thumbnail
            </button>
          )}
          <input
            type="file"
            onChange={handleThumbnailChange}
            ref={hiddenFileInput1}
            style={{ display: "none" }}
          />
          {errorState.course_banner && (
            <span className="error-msg">{errorState.course_banner}</span>
          )}
        </div>

        <h5>Upload Certificate</h5>
        <div className="cd-image-uploader">
          <div>
            <img
              className={`${
                detailsState.certificate_template ? "uploadedImg" : "icon"
              }`}
              src={
                detailsState.certificate_template
                  ? detailsState.certificate_template
                  : CameraIcon
              }
              alt="camera-icon"
            />
          </div>

          {!(role === "user" || role === "manager") && (
            <button onClick={handleCertificateUpload}>
              Upload Course Certificate
            </button>
          )}
          <input
            type="file"
            onChange={handleCertificateChange}
            ref={hiddenFileInput2}
            style={{ display: "none" }}
          />
          {errorState.certificate_template && (
            <span className="error-msg">{errorState.certificate_template}</span>
          )}
        </div>

        {!(role === "user" || role === "manager") && (
          <div className="cd-button-bottom">
            <button onClick={cancelCourseHandler}>Cancel</button>
            <button onClick={saveCourseHandler}>Save</button>
            {courseId !== null && (
              <button onClick={deleteCourseHandler}>Delete</button>
            )}
          </div>
        )}
      </div>

      {/* <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={() => setSnackbarOpen(false)}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
      >
        <Alert
          onClose={() => setSnackbarOpen(false)}
          severity={snackbarSeverity}
          sx={{ width: "100%" }}
        >
          {snackbarMessage}
        </Alert>
      </Snackbar> */}
      {/* Confirmation Popup */}
      {confirmState.open && (
        <ConfirmationPopup
          message={confirmState.message}
          onConfirm={() => {
            confirmState.onConfirm();
            setConfirmState({ ...confirmState, open: false });
          }}
          onCancel={() => setConfirmState({ ...confirmState, open: false })}
          onClose={() => setConfirmState({ ...confirmState, open: false })}
        />
      )}

      {/* Single AlertMessage Component */}
      {alertState.open && (
        <AlertMessage
          message={alertState.message}
          variant={alertState.variant}
          onClose={() => setAlertState({ ...alertState, open: false })}
        />
      )}
    </div>
  );
};

export default CourseDetailsTab;
