import AdminLeftSideBar from "../components/admin/common/AdLeftSideBar";
import AdminNavbar from "../components/admin/common/AdNavbar";
import "../styling/admin/AdDashboard.css";
import { useLocation, useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import axiosInstance from "../utils/axiosInstance";
import { getHeaders } from "../components/utils/useAuth";
import { getItem } from "../utils/storage";

export default function AdDashboard() {
  useEffect(() => {
    axiosInstance
      .get(`/users/user_details`)
      .then((res) => {
        setFirstName(res.data["first_name"]);
      })
      .catch((err) => {
        console.error("Error fetching user data:", err);
      });
  }, []);
  // if token is expired, navigate to login
  useEffect(() => {
    const token = getItem("token");
    if (!token) {
      navigate("/login");
    }
  }, []);

  const { state } = useLocation();
  const navigate = useNavigate();
  const [firstName, setFirstName] = useState("");
  const [role, setRole] = useState("");

  useEffect(() => {
    const userRole = sessionStorage.getItem("role");
    setRole(userRole);
  }, []);

  // Common function to handle navigation to different routes.
  const handleNavigation = (path) => {
    navigate(path);
  };

  return (
    <div className="ad-dashboard-bg">
      {/* Sidebar component for admin navigation */}
      <AdminLeftSideBar />
      <div className="ad-dash-right-section">
        {/* Navbar component for admin dashboard */}
        <AdminNavbar />
        {/* Welcome message for the user */}
        <h1>Welcome {firstName}</h1>
        <div className="ad-dash-rs-body">
          <div className="ad-cards">
            {/* Card for navigating to Course Management section */}
            <div
              className="ad-cards-bg"
              onClick={() => handleNavigation("/course-management")}
            >
              <h4>Course Management</h4>
            </div>
            {/* Card for navigating to User Management section */}
            {(role === "admin" || role === "manager") && (
              <div
                className="ad-cards-bg"
                onClick={() => handleNavigation("/user-management")}
              >
                <h4>Employee Management</h4>
              </div>
            )}
            {(role === "admin" || role === "editor") && (
              <div
                className="ad-cards-bg"
                onClick={() => handleNavigation("/dashboard")}
              >
                <h4>User Dashboard</h4>
              </div>
            )}
            {/* <div
              className="ad-cards-bg"
              onClick={() => handleNavigation("/organisation-tree")}
            >
              <h4>User Organisation Tree</h4>
            </div> */}
          </div>
        </div>
      </div>
    </div>
  );
}
